import React, { Fragment } from 'react'
import { Chart, registerables } from 'chart.js'
import { Bar, Pie } from 'react-chartjs-2'
import ExcelExport from './components/excelExport'
import categoryTotalData from './data/byCategories.json'
import countriesTotalData from './data/byCountry.json'
import "./scss/statistics.scss"

Chart.register(...registerables)

class Statistics extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            permission: 'statistics',
            eventName: 'IFX INTERNATIONAL Cyprus 2024',
            totalData: {
                registered: 5434, //5169
                registeredOnSpot: 812,
                registeredOnline: 3477,
                totalRegisteredOnline: 4357,
                percentageOfOnlineRegistrationsAttendees: '86,57%',
                registeredByCountry: 122,
                registeredByCategory: 43,
                printed: 4289,
                cash: '2352.20',
                card: '18962.20'
            },
            byPackage: {
                'P1': 2232,
                'P2': 1262, 
                'P3': 636,
                'VIP': 1205,
                'No package': 99,
            },
            byType: {
                'Regular': 4231,
                'Sponsor': 1063,
                'Speaker': 99,
                'SpeakerGuest': 41,
            },
            byPaymentMethod: {
                'Online': 1177,
                'Cash': 11,
                'Card': 41
            }
        }

        this.byPackage = {
            labels: [
              'P1',
              'P2',
              'P3',
              'VIP',
              'No package',
            ],
            datasets: [{
              label: 'registrations',
              data: [2412, 1580, 983, 991, 84],
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(12, 12, 12)',
                'rgb(111, 12, 111)'
              ],
              hoverOffset: 4
            }]
        }

        this.byPackageOptions = {
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Registrations by package',
              },
            }
        }

        this.byPayment = {
            labels: [
              'Online',
              'Card',
              'Cash',
            ],
            datasets: [{
              label: 'registrations',
              data: [1177, 41, 11],
              backgroundColor: [
                'rgb(123, 199, 132)',
                'rgb(124, 62, 135)',
                'rgb(205, 205, 186)',
              ],
              hoverOffset: 4
            }]
        }

        this.byPaymentOptions = {
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Registrations by payment method',
              },
            }
        }

        this.byType = {
            labels: [
              'Regular',
              'Sponsor',
              'Speaker',
              'Speaker guest',
            ],
            datasets: [{
              label: 'registrations',
              data: [4231, 1063, 99, 41],
              backgroundColor: [
                'rgb(12, 99, 132)',
                'rgb(154, 162, 135)',
                'rgb(255, 205, 86)',
                'rgb(124, 12, 12)',
              ],
              hoverOffset: 4
            }]
        }

        this.byTypeOptions = {
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Registrations by type',
              },
            }
        }

        this.countryLabels = [
            'Cyprus',
            'Israel',
            'United Kingdom (UK)',
            'Argentina',
            'United Arab Emirates',
            'Ukraine',
            'Armenia',
            'Bulgaria',
            'Germany',
            'Spain'
        ]

        this.countryData = {
            labels: this.countryLabels,
            datasets: [
                {
                    label: 'Number of registrations',
                    data: [3134, 357, 352, 121, 102, 95, 88, 56, 45, 45],
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        }
        this.countryOptions = {
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Top ten registered by countries',
              },
            },
        }

        this.categoryLabels = [
            'No category',
            'FX/CFD Broker',
            'Affiliate',
            'Affiliate Network',
            'Crypto Exchange',
            'Payment Solution Provider (EMIs)',
            'Institutional Broker',
            'Liquidity Provider',
            'Introducing Broker (IB)',
            'Other Service Provider',
        ];
        
        this.categoryData = {
            labels: this.categoryLabels,
            datasets: [
                {
                    label: 'Number of registrations',
                    data: [2023, 1589, 333, 184, 168, 114, 83, 80, 67, 53],
                    backgroundColor: 'rgba(155, 239, 132, 0.5)',
                },
            ],
        }

        this.categoryOptions = {
            // responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Top ten registered by categories',
              },
            },
        };
    }

    render() {
        return (
            <Fragment>
                <div style={{overflow: 'auto', height: '760px'}}>
                    {/**
                     * Main data table
                     */}
                    <h1>General Statistics for {this.state.eventName}</h1>
                    <table style={{textAlign: 'center'}} className='statistics_table'>
                        <thead>
                          <tr>
                          <th style={{border: '1px solid black'}}>Total registered</th>
                            <th style={{border: '1px solid black'}}>Total printed</th>
                            <th style={{border: '1px solid black'}}>Total registered on spot</th>
                            <th style={{border: '1px solid black'}}>Total registered online</th>
                            <th style={{border: '1px solid black'}}>Total registered by country</th>
                            <th style={{border: '1px solid black'}}>Total registered by category</th>
                          </tr>
                            
                        </thead>
                        <tbody>
                          <tr>
                          <td style={{border: '1px solid black'}}>{this.state.totalData.registered}</td>
                            <td style={{border: '1px solid black'}}>{this.state.totalData.printed}</td>
                            <td style={{border: '1px solid black'}}>{this.state.totalData.registeredOnSpot}</td>
                            <td style={{border: '1px solid black'}}>{this.state.totalData.totalRegisteredOnline}</td>
                            <td style={{border: '1px solid black'}}>{this.state.totalData.registeredByCountry}</td>
                            <td style={{border: '1px solid black'}}>{this.state.totalData.registeredByCategory}</td>
                          </tr>
                        </tbody>
                    </table>
                    <hr/>
                    <table style={{textAlign: 'center'}}>
                        <thead>
                          <tr>
                          <th style={{border: '1px solid black'}}>Total sponsors</th>
                            <th style={{border: '1px solid black'}}>Total speakers</th>
                            <th style={{border: '1px solid black'}}>Total speaker guests</th>
                            <th style={{border: '1px solid black'}}>Total attendence of users registered online</th>
                            <th style={{border: '1px solid black'}}>Total attendence of users registered onspot</th>
                            <th style={{border: '1px solid black'}}>Percentage of online registrations who attended</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                          <td style={{border: '1px solid black'}}>{this.state.byType.Sponsor}</td>
                            <td style={{border: '1px solid black'}}>{this.state.byType.Speaker}</td>
                            <td style={{border: '1px solid black'}}>{this.state.byType.SpeakerGuest}</td>
                            <td style={{border: '1px solid black'}}>{this.state.totalData.registeredOnline}</td>
                            <td style={{border: '1px solid black'}}>{this.state.totalData.registeredOnSpot}</td>
                            <td style={{border: '1px solid black'}}>{this.state.totalData.percentageOfOnlineRegistrationsAttendees}</td>
                          </tr>
                        </tbody>
                    </table>
                    <br />
                    <p>Amount payed in cash: <b>{this.state.totalData.cash}</b></p>
                    <p>Amount payed in card: <b>{this.state.totalData.card}</b></p>
                    <br />
                    <br />

                    <div>
                    </div>
                    
                    {/**
                     * By package
                     */}
                    <div style={{height: '500px', paddingLeft: '250px'}}>      
                        <Pie
                            data={this.byPackage}
                            options={this.byPackageOptions}
                        />
                    </div>
                    <br/>
                    <br/>
                    {/**
                     * By type
                     */}
                    <div style={{height: '500px', paddingLeft: '250px'}}>      
                        <Pie
                            data={this.byType}
                            options={this.byTypeOptions}
                        />
                    </div>
                    <br/>
                    <br/>
                    {/**
                     * By payment
                     */}
                    <div style={{height: '500px', paddingLeft: '250px'}}>      
                        <Pie
                            data={this.byPayment}
                            options={this.byPaymentOptions}
                        />
                    </div>
                    <br/>
                    <br/>
                    {/**
                     * Top ten by country
                     */}
                    <div style={{height: '500px'}}>      
                        <Bar
                            data={this.countryData}
                            options={this.countryOptions}
                        />
                    </div>
                    <div style={{paddingLeft: '250px'}}>
                        <ExcelExport excelData={countriesTotalData} fileName={this.state.eventName+" attendees by country"} buttonName={"Export by countries"}/>
                    </div>
                    <br/>
                    <br/>
                    {/**
                     * Top ten by category
                     */}
                    <div style={{height: '500px'}}>      
                        <Bar
                            data={this.categoryData}
                            options={this.categoryOptions}
                        />
                    </div>
                    <div style={{paddingLeft: '250px'}}>
                        <ExcelExport excelData={categoryTotalData} fileName={this.state.eventName+" attendees by category"} buttonName={"Export by categories"}/>
                    </div>

                </div>
            </Fragment>
        )
    }

}

export default Statistics
import React from 'react'
import Grid from "../../../nix_components/ui_components/layouts/Grid/Grid"


const NoScopes = () => {
    return (
        <Grid fullScreen={true}
              style={{justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(30, 30, 30, 0.7)'}}>
            <Grid style={{color: 'rgb(248, 250, 251)', textDecoration: 'underline'}}>
                Please contact your administrator, you dont have any permissions/scopes set
            </Grid>
        </Grid>
    )
}

export default NoScopes
import request from "superagent";

class UF {
  constructor() {
    this.dataProvider = null;
  }

  makeRequest = (
    type,
    url,
    data,
    includeCredentials,
    success,
    error,
    showLoading = true,
    showAlert = true
  ) => {
    let message = "";
    let req = null;
    switch (type) {
      case "GET":
        req = request.get(url);
        break;
      case "POST":
        req = request.post(url);
        message = "Success";
        break;
      case "PUT":
        req = request.put(url);
        message = "Success";
        break;
      case "DEL":
        req = request.del(url);
        message = "Success";
        break;
      default:
    }

    if (includeCredentials) {
      req = req.withCredentials();
    }

    if (data) {
      if (type == "GET") {
        req = req.query(data);
      } else {
        req = req.send(data);
      }
    }

    req = req.set(
      "Access-Control-Allow-Origin",
      global.location.protocol + "//" + global.location.host
    );

    if (includeCredentials) {
      req = req.set("authorization", "Bearer " + localStorage.getItem("token"));
    }
    //req.type('application/json').then(success, error);
    req.type("application/json").then(
      (data) => {
        if (showLoading) {
          this.setMaskVisibility(false);
        }
        if (type !== "GET" && showAlert && includeCredentials) {
          this.setAlertVisibility(true, message, "success");
        }
        success(data);
      },
      (err) => {
        if (err.status === 401) {
          this.logout();
        }
        if (err) {
          this.setMaskVisibility(false);
          if (showAlert) {
            this.setAlertVisibility(true, err.toString(), "error");
          }
        }
        error(err);
      }
    );

    if (showLoading) {
      this.setMaskVisibility(true);
    }
  };

  makeRequest_v2 = (
    type,
    url,
    data,
    includeCredentials,
    showLoading = true,
    showAlert = true
  ) => {
    return new Promise(async (resolve, reject) => {
      let message = "";
      let req = null;

      switch (type) {
        case "GET":
          req = request.get(url);
          break;
        case "POST":
          req = request.post(url);
          message = "Success";
          break;
        case "PUT":
          req = request.put(url);
          message = "Success";
          break;
        case "DEL":
          req = request.del(url);
          message = "Success";
          break;
        default:
      }

      if (includeCredentials) {
        req = req.withCredentials();
      }

      if (data) {
        if (type == "GET") {
          req = req.query(data);
        } else {
          req = req.send(data);
        }
      }

      req = req.set(
        "Access-Control-Allow-Origin",
        global.location.protocol + "//" + global.location.host
      );

      if (includeCredentials) {
        req = req.set(
          "authorization",
          "Bearer " + localStorage.getItem("token")
        );
      }

      if (showLoading) {
        this.setMaskVisibility(true);
      }

      try {
        const data = await req.type("application/json");
        if (showLoading) {
          this.setMaskVisibility(false);
        }
        if (type !== "GET" && showAlert && includeCredentials) {
          this.setAlertVisibility(true, message, "success");
        }
        resolve(data);
      } catch (err) {
        if (err) {
          this.setMaskVisibility(false);
        }
        if (showAlert) {
          this.setAlertVisibility(true, err.toString(), "error");
        }
        if (err.status === 401) {
          this.logout();
        }
        reject(err);
      }
    });
  };

  makeRequest2 = (type, url, data, includeCredentials, success, error) => {
    let req = null;
    switch (type) {
      case "GET":
        req = request.get(url);
        break;
      case "POST":
        req = request.post(url);
        break;
      case "PUT":
        req = request.put(url);
        break;
      case "DEL":
        req = request.del(url);
        break;
      default:
    }

    if (includeCredentials) {
      req = req.withCredentials();
    }

    if (data) {
      req = req.send(data);
    }

    req = req.set(
      "Access-Control-Allow-Origin",
      global.location.protocol + "//" + global.location.host
    );

    if (includeCredentials) {
      req = req.set("authorization", "Bearer " + localStorage.getItem("token"));
    }
    req.type("application/json").then(
      (data) => {
        this.setMaskVisibility(false);
        success(data);
      },
      (err) => {
        if (err.status === 401) {
          this.logout();
        }
        if (err) {
          this.setMaskVisibility(false);
        }
        error(err);
      }
    );

    this.setMaskVisibility(true);
  };

  handleEventBasedOnUrl = () => {
    const url = window.location.href;
    let event_name = "";
    if (url.includes("realty")) {
      event_name = "RO";
    } else if (url.includes("financemagnates")) {
      event_name = "FM";
    } else if (url.includes("ultimatefintech")) {
      event_name = "IFX";
    } else {
      event_name = "IFX";
    }
    return event_name;
  };

  logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("event_settings");
    window.location.href = "/";
  };

  setAlertVisibility = (show, message, type) => {
    if (global.setAlertVisibility) {
      global.setAlertVisibility(show, message, type);
    }
  };

  setMaskVisibility = (visible) => {
    if (global.loadingSetVisibility) {
      global.loadingSetVisibility(visible);
    }
  };
}

export default UF;

import React from 'react';
import withRouter from '../../../../nix_components/components/withRouter';
import ExcelExport from '../../Events/Statistics/components/excelExport';
class Votes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            permission: 'awards-admin',
            scopeCondition: false,
            loaded: false,
            nominations: [],
            categories: [],
            nominees: [],
            votes: [],
            nominationsWithVotes: [],
            votesForExport: [],
        }
    }

    buttons = {}

    findCurrentPermission = () => {
        const user = typeof localStorage.getItem('user') === 'string' ? JSON.parse((localStorage.getItem('user'))) : localStorage.getItem('user');
        const scopes = user.hasOwnProperty('scopes') ? typeof user.scopes === 'string' ? JSON.parse(user.scopes) : user.scopes : null;
        this.state.scopeCondition = scopes.findIndex(permission => permission.split(':')[0] === this.state.permission && permission.includes('write')) > -1;
        this.setState({loaded : true});
    }

    loadCategories = async () => {
        await global.UF.dataProvider.get(`categories`, {award_id: global.UF.award_id}, (categories) => {
            this.setState({categories: categories})
        })
    }

    loadNominations = async () => {
        await global.UF.dataProvider.get(`nominations`, {award_id: global.UF.award_id}, (nominations) => {
            this.setState({nominations: nominations})
        });
    }

    loadNominees = async () => {
        await global.UF.dataProvider.get(`nominees`, {award_id: global.UF.award_id}, (nominees) => {
            this.setState({nominees: nominees})
        })
    }

    loadVotes = async () => {
        await global.UF.dataProvider.get(`votes`, {award_id: global.UF.award_id}, (votes) => {
            this.setState({votes: votes});
        });
    }

    loadVotesForExport = () => {
        if (this.state.nominations.length && this.state.nominees.length && this.state.votes.length) {
            const nominations = this.state.nominations;
            const votes = this.state.votes;
            const nominees = this.state.nominees;
            const votesForExport = [];
            votes.forEach((vote) => {
                const nominationName = nominations.filter((nomination) => nomination.id === vote.nomination_id);
                const nominee = nominees.filter((nominee) => nominee.id === vote.nominee_id);
                let parsedVote = {
                    email: vote.email,
                    award: nominationName ? nominationName[0].name : 'Nomination was deleted',
                    nominee: nominee.length ? nominee[0].name : "Nominee was deleted"
                }

                votesForExport.push(parsedVote);
            });

            this.setState({votesForExport: votesForExport});
        }
    }

    createNominationsWithVotes = () => {
        if (this.state.nominations.length && this.state.nominees.length && this.state.votes.length) {
            const categories = this.state.categories;
            const nominations = this.state.nominations;
            const votes = this.state.votes;
            const nominees = this.state.nominees;
            const nominationsWithVotes = [];

            nominations.forEach(async (nomination) => {
                const cat = categories.filter((category) => category.id === nomination.category_id);
                let nominationWithVotes = {
                    name: nomination.name,
                    category: cat[0].name,
                    votes: []
                }
                let rawVotes = [];

                votes.forEach(async (vote) => {
                    if (vote.nomination_id === nomination.id) {
                        const nominee = nominees.filter((nominee) => nominee.id === vote.nominee_id);
                        if (nominee && nominee.length) {
                            rawVotes.push(nominee[0].name);
                        }
                    }
                });

                let counts = {};
                rawVotes.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
                const talliedVotes = [];
                
                for (let prop in counts) {
                    if (Object.prototype.hasOwnProperty.call(counts, prop)) {
                        let nomineeWithVotes = {
                            name: prop,
                            votes: counts[prop]
                        }
                        talliedVotes.push(nomineeWithVotes);
                    }
                }

                nominationWithVotes.votes = talliedVotes.sort(({votes: a}, {votes: b}) => b-a);
                nominationsWithVotes.push(nominationWithVotes);
            });

            this.setState({nominationsWithVotes: nominationsWithVotes});
        }
    }

    componentDidMount() {
        this.findCurrentPermission();
        this.loadCategories();
        this.loadNominations();
        this.loadNominees();
        this.loadVotes();
        setTimeout(() => {
            this.createNominationsWithVotes();
        }, 1000);
        setTimeout(() => {
            this.loadVotesForExport();
        }, 1000);
    }

    render() {
        return (
            <div style={{height: '600px', overflowY: 'auto'}}>
                <h2 style={{textAlign: 'center'}}>Current votes</h2>
                <div>
                    <ExcelExport excelData={this.state.votesForExport} fileName={"votes"} buttonName={"Export votes"}/>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {this.state.nominationsWithVotes.map((nomination, index) => {
                        return (
                            <div key={index} style={{flex: '1', flexBasis: '20%', border: '1px solid black'}}>
                                <h3 style={{textAlign: 'center'}}>{nomination.name} ({nomination.category})</h3>
                                <h4 style={{textAlign: 'center'}}>Votes</h4>
                                {nomination.votes.map((nominee, index) => {
                                    return (
                                        <p key={index} style={{textAlign: 'center'}}>{nominee.name} <b>{nominee.votes}</b></p>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

export default withRouter(Votes)
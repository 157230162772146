import React, { Fragment } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import data_types from "../../../../nix_components/data/data_types";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { IconButton } from "@mui/material";
import ReorderIcon from "@mui/icons-material/Reorder";
import ReorderModal from "../../../../components/modals/ReorderModal";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import TransferDataModal from "../../../../components/modals/TransferDataModal";

class SponsorsExhibitors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reorderOpen: false,
      transferOpen: false,
      permission: "sponsor",
      scopeCondition: false,
      loaded: false,
    };
  }

  buttons = {};

  tools = {
    reorder: () => {
      return this.state.scopeCondition ? (
        <Tooltip title={"Reorder"}>
          <IconButton onClick={() => this.setState({ reorderOpen: true })}>
            <ReorderIcon />
          </IconButton>
        </Tooltip>
      ) : null;
    },
    transfer: () => {
      return this.state.scopeCondition ? (
        <Tooltip title={"Transfer"}>
          <IconButton onClick={() => this.setState({ transferOpen: true })}>
            <MoveUpIcon />
          </IconButton>
        </Tooltip>
      ) : null;
    },
  };

  formConfig = {
    rows: [
      [
        /*{
                    label: 'Template Sponsors',
                    field: 'Template Sponsors',
                    // field: 'template_sponsors',
                    datatype: 'template'
                },*/
        {
          label: "Published",
          field: "published",
          datatype: data_types.boolean,
        },
      ],
      [
        {
          label: "Company Name",
          field: "name",
          datatype: data_types.string,
        },
        {
          label: "Company Email",
          field: "email",
          datatype: data_types.string,
        },
        {
          label: "Company Phone",
          field: "phone",
          datatype: data_types.string,
        },
      ],
      [
        {
          field: "logo",
          label: "Colored logo",
          datatype: data_types.file,
          requirements: { width: 300, height: 100 },
        },
        {
          field: "logo_white",
          label: "White logo",
          datatype: data_types.file,
          requirements: { width: 300, height: 100 },
        },
        {
          field: "country",
          label: "Country",
          datatype: data_types.reference,
          reference: "countries",
        },
      ],
      [
        {
          label: "Booth number(s)",
          field: "booth_numbers",
          multiple: true,
          datatype: data_types.number,
        },
      ],
      [
        {
          label: "Sponsorship Type(s)",
          field: "types",
          multiple: true,
          datatype: data_types.reference,
          reference: "sponsor_types",
        },
        {
          label: "Sponsorship Category",
          field: "category_id",
          datatype: data_types.reference,
          reference: "sponsor_categories",
        },
      ],
      [
        "website",
        {
          label: "Contact person",
          field: "contact_name",
          datatype: data_types.string,
        },
        {
          label: "Contact email",
          field: "contact_email",
          datatype: data_types.string,
        },
      ],
      [
        {
          label: "Description",
          field: "description",
          datatype: data_types.string,
          multiline: true,
        },
      ],
    ],
  };

  gridProps = {
    fields: [
      "name",
      "booth_numbers",
      "types",
      "category_id",
      "published",
      "ordering",
      "created_at",
    ],
  };

  renderTransferDataModal = () => {
    return (
      <TransferDataModal
        open={this.state.transferOpen}
        onClose={() => this.setState({ transferOpen: false })}
        table={"sponsors"}
      />
    );
  };

  componentDidMount() {
    if (global.UF.EVENT_SETTINGS?.EVENT === "FM") {
      global.UF.dataProvider.datastructure[`sponsors`].fieldsArr.find(
        (field) => field.Field === "email"
      ).validators = [];
      global.UF.dataProvider.datastructure[`sponsors`].ignoreValidation.push(
        "email"
      );
    }
    this.findCurrentPermission();
  }

  fetchData = () => {};

  getFetchData = (fetchData) => {
    this.fetchData = fetchData;
  };

  renderReorderingModal = () => {
    return (
      <ReorderModal
        open={this.state.reorderOpen}
        onClose={() =>
          this.setState({ reorderOpen: false }, () => this.fetchData())
        }
        table={"sponsors"}
        onClick={this.fetchData}
      />
    );
  };

  handleBeforeAddNew = (dataItem, callback) => {
    dataItem.event_id = global.UF.event_id;
    global.UF.makeRequest(
      `POST`,
      `/api/get-ordering`,
      { table: "sponsors" },
      true,
      (data) => {
        dataItem.ordering = data.body.ordering;
        if (callback) {
          callback();
        }
      },
      (error) => {
        console.error(error);
      },
      true,
      false
    );
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  render() {
    return (
      <Fragment>
        {this.state.reorderOpen && this.renderReorderingModal()}
        {this.state.transferOpen && this.renderTransferDataModal()}
        <UF_Content
          table="sponsors"
          id={this.props.event_id}
          title="Sponsor Exhibitors"
          permission={"sponsor"}
          buttons={this.buttons}
          edit={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          add={this.state.scopeCondition}
          sync={this.state.scopeCondition}
          export={this.state.scopeCondition}
          tools={this.tools}
          formConfig={this.formConfig}
          fetchData={this.getFetchData}
          gridProps={this.gridProps}
          beforeAddNew={this.handleBeforeAddNew}
        />
      </Fragment>
    );
  }
}

export default SponsorsExhibitors;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./controls.scss";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import IconButton from "@mui/material/IconButton/IconButton";
import PreviewIcon from "@mui/icons-material/Preview";

class Nx_Ctrl_TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.inputStyle = this.props.inputStyle
      ? this.props.inputStyle
      : { fontSize: this.props.fontSize };
    this.inputLabelStyle = this.props.inputLabelStyle
      ? this.props.inputLabelStyle
      : { fontSize: this.props.fontLabelSize };
  }

  onChange = (event) => {
    this.props.onChange(event.target.value);
  };

  render() {
    return (
      <Fragment>
        <TextField
          label={
            this.props.fieldConfig
              ? this.props.fieldConfig.label
              : this.props.label
          }
          value={
            this.props.value !== null && this.props.value !== undefined
              ? this.props.value
              : ""
          }
          variant={this.props.variant}
          disabled={
            this.props.fieldConfig
              ? this.props.fieldConfig.disabled
              : this.props.disabled
          }
          error={this.props.error}
          autoFocus={this.props.autoFocus}
          fullWidth={this.props.fullWidth}
          helperText={
            this.props.helperText || this.props.fieldConfig?.helperText
              ? this.props.helperText || this.props.fieldConfig?.helperText
              : ""
          }
          type={this.props.type}
          inputProps={{
            style: this.inputStyle,
          }}
          InputLabelProps={{ style: this.inputLabelStyle }}
          onKeyDown={this.props.onKeyDown}
          onChange={this.onChange}
          multiline={
            this.props.fieldConfig
              ? this.props.fieldConfig.multiline
              : this.props.multiline
          }
          rows={
            this.props.fieldConfig
              ? this.props.fieldConfig.multiline
                ? 5
                : null
              : null
          }
          size={this.props.size}
          className={`nx_textfield ${
            this.props.className ? this.props.className : ""
          } ${this.props.error ? "nx_error" : ""}`}
        ></TextField>
        {this.props.websitePreview ? (
          <Fragment>
            <InputAdornment
              position="start"
              className={"nx_textfield_website_preview"}
            >
              <IconButton
                onClick={() =>
                  window.open(
                    this.props.value.includes("http")
                      ? this.props.value
                      : `https://${this.props.value}`
                  )
                }
              >
                <PreviewIcon />
              </IconButton>
            </InputAdornment>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

Nx_Ctrl_TextInput.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["text", "password", "number", "color", "file"]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  fontSize: PropTypes.string,
  fontLabelSize: PropTypes.string,
  multiline: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "small", "string"]),
};

Nx_Ctrl_TextInput.defaultProps = {
  className: "",
  disabled: false,
  fullWidth: true,
  autoFocus: false,
  error: false,
  helperText: "",
  type: "text",
  fontSize: "12px",
  fontLabelSize: "11px",
  multiline: false,
  size: "small",
};

export default Nx_Ctrl_TextInput;

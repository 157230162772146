import React, { Fragment } from 'react'
import withRouter from '../../../../nix_components/components/withRouter'

class CurrentNominees extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            permission : 'award',
            scopeCondition : false,
            loaded : false,
            nominations: [],
            nominees: [],
            nominationsWithNominees: [],
            categories: []
        }
    }

    findCurrentPermission = () => {
        const user = typeof localStorage.getItem('user') === 'string' ? JSON.parse((localStorage.getItem('user'))) : localStorage.getItem('user')
        const scopes = user.hasOwnProperty('scopes') ? typeof user.scopes === 'string' ? JSON.parse(user.scopes) : user.scopes : null;
        this.state.scopeCondition = scopes.findIndex(permission => permission.split(':')[0] === this.state.permission && permission.includes('write')) > -1;
        this.setState({loaded : true})
    }

    loadCategories = async () => {
        await global.UF.dataProvider.get(`categories`, {award_id: global.UF.award_id}, (categories) => {
            this.setState({categories: categories})
        })
    }

    loadNominations = async () => {
        await global.UF.dataProvider.get(`nominations`, {award_id: global.UF.award_id}, (nominations) => {
            this.setState({nominations: nominations})
        })
    }

    loadNominees = async () => {
        await global.UF.dataProvider.get(`nominees`, {award_id: global.UF.award_id}, (nominees) => {
            this.setState({nominees: nominees})
        })
    }

    createNominationsWithNominees = () => {
        if (this.state.nominations.length && this.state.nominees.length) {
            let nominations = this.state.nominations
            let nominees = this.state.nominees
            let categories = this.state.categories
            let nominationsWithNominees = []
            nominations.forEach( async (nomination) => {
                let cat = categories.filter((category) => category.id === nomination.category_id)
                let nominationWithNominee = {
                    name: nomination.name,
                    category: cat[0].name,
                    nomineesCount: 0,
                    nominees: []
                }
                nominees.forEach((nominee) => {
                    const nominationIds = JSON.parse(nominee.nomination_id)
                    if (nominationIds && nominationIds.length) {
                        nominationIds.forEach((nominationId) => {
                            if (nomination.id === nominationId) {
                                let nomineeForNomination = {
                                    name: nominee.name
                                }

                                nominationWithNominee.nominees.push(nomineeForNomination)
                                nominationWithNominee.nomineesCount = nominationWithNominee.nomineesCount + 1
                            }
                        })
                    }
                })

                nominationsWithNominees.push(nominationWithNominee)
            })
            
            this.setState({nominationsWithNominees: nominationsWithNominees})
        }
    }

    componentDidMount() {
        this.findCurrentPermission()
        this.loadCategories()
        this.loadNominations()
        this.loadNominees()
        setTimeout(() => {
            this.createNominationsWithNominees()
        }, 1000)
    }

    render() {
        return (
            <Fragment>
                <div style={{height: '600px', overflowY: 'auto'}}>
                    <h2 style={{textAlign: 'center'}}>Current nominations</h2>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {this.state.nominationsWithNominees.map((nomination, index) => {
                            return (
                                <div key={index} style={{flex: '1', flexBasis: '20%', border: '1px solid black'}}>
                                    <h3 style={{textAlign: 'center'}}>{nomination.name} ({nomination.category})</h3>
                                    <h4 style={{textAlign: 'center'}}>Nominees: {nomination.nomineesCount}</h4>
                                    {nomination.nominees.map((nominee, index) => {
                                        return (
                                            <p key={index} style={{textAlign: 'center'}}>{nominee.name}</p>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(CurrentNominees)
import React, { Fragment } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import TransferDataModal from "../../../../components/modals/TransferDataModal";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { IconButton } from "@mui/material";

class SponsorsTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: "sponsor",
      transferOpen: false,
      scopeCondition: false,
      loaded: false,
    };
  }

  tools = {
    transfer: () => {
      return this.state.scopeCondition ? (
        <Tooltip title={"Transfer"}>
          <IconButton onClick={() => this.setState({ transferOpen: true })}>
            <MoveUpIcon />
          </IconButton>
        </Tooltip>
      ) : null;
    },
  };

  buttons = {};

  formConfig = {
    rows: [["name", {}]],
  };

  gridProps = {
    fields: ["name", "created_at"],
  };

  handleBeforeAddNew = (dataItem, callback) => {
    dataItem.event_id = global.UF.event_id;
    if (callback) {
      callback();
    }
  };

  renderTransferDataModal = () => {
    return (
      <TransferDataModal
        open={this.state.transferOpen}
        onClose={() => this.setState({ transferOpen: false })}
        table={"sponsor_types"}
      />
    );
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  componentDidMount() {
    this.findCurrentPermission();
  }

  render() {
    return (
      <Fragment>
        {this.state.transferOpen && this.renderTransferDataModal()}
        <UF_Content
          table="sponsor_types"
          id={this.props.event_id}
          title="Sponsor Types"
          permission={"sponsor"}
          tools={this.tools}
          buttons={this.buttons}
          edit={this.state.scopeCondition}
          add={this.state.scopeCondition}
          export={this.state.scopeCondition}
          sync={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          formConfig={this.formConfig}
          gridProps={this.gridProps}
          beforeAddNew={this.handleBeforeAddNew}
        />
      </Fragment>
    );
  }
}

export default SponsorsTypes;

import React, {useRef, useEffect, Fragment, useState} from 'react'
import "./ui_grid.scss"
import PropTypes from 'prop-types'

const Grid = (props) => {

    const grid = useRef()
    const index = props.index

    const [cssStyle, setStyle] = useState()


    const handleSettingGridProps = () => {
        const style = props.style ? Object.assign({}, props.style) : {}
        if (props.fullScreen) {
            grid.current.style.width = "100%"
            grid.current.style.height = "100%"
        }
        if (props.className) {
            if (!grid.current.className.includes(props.className)) {
                grid.current.className += ` ${props.className}`
            }
        }
        grid.current.style.display = props.hidden ? 'none' : 'grid'
        setStyle(style)
    }

    useEffect(() => {
        handleSettingGridProps()
    }, [props.style, props.className])

    return (
        <Fragment>
            <div className="grid_container" key={index} ref={grid} style={cssStyle}>
                {props.children}
            </div>
        </Fragment>
    )
}


Grid.propTypes = {
    fullScreen: PropTypes.oneOf([true, false]),
    className: PropTypes.string,
    hidden: PropTypes.bool
}

Grid.defaultProps = {
    fullScreen: false,
}

export default Grid
import React from 'react'
import {Route, Routes} from "react-router-dom"
import Awards from "./Awards/Awards"
import Events from "./Events/Events"
import Users from "./Users/Users"
import GroupIcon from '@mui/icons-material/Group'
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents"
import { EventsSettings } from './Events/EventsSettings'
import Settings from '@mui/icons-material/Settings'

class Administrator extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            updateMenuItems: false,
            loaded: false
        }
        this.prefix = '../administrator'
        this.menuItems = [
            {
                title: 'Users',
                level: 1,
                url: '/administrator/users',
                scope: 'user',
                icon: <GroupIcon/>,
                element: () => {
                    return <Users/>
                }
            },
            {
                title: 'Events',
                icon: <CalendarMonthIcon/>,
                scope: 'event',
                level : 1,
                open : false,
                children : [
                    {
                        title: 'Events',
                        url: `../administrator/events`,
                        level : 2,
                        icon: <CalendarMonthIcon/>,
                        element: () => {
                          return (
                            <Events
                              fetchMenuItems={this.props.fetchMenuItems}
                            />
                          );
                        }
                    },
                    {
                        title : 'Event Settings',
                        url:`../administrator/event-settings`,
                        level : 2,
                        icon : <Settings/>,
                        element: () => {
                            return <EventsSettings />;
                          }
                    }
                ] 
            },
            {
                title: 'Awards',
                level: 1,
                url: '/administrator/awards',
                icon: <EmojiEventsIcon/>,
                scope: 'award',
                element: () => {
                    return <Awards fetchMenuItems={this.props.fetchMenuItems}/>
                }
            },
        ]
    }

    findCurrentRoute = () => {
        if (this.props.menuItems && Array.isArray(this.props.menuItems)) {
            const currentIndex = this.props.menuItems.findIndex(mI => {
                return (mI.hasOwnProperty('write') || mI.hasOwnProperty('read'))
            })
            const currentItem = currentIndex > -1 ? this.props.menuItems[currentIndex] : null
            if (currentItem != null) {
                if (currentItem.hasOwnProperty('children') && currentItem.children.length > 0) {
                    const currentChild = currentItem.children[0]
                    return <Route path={`/*`} element={currentChild.element()} event_id={this.state.event_id}/>
                } else {
                    return (
                        <Route path={`/*`} element={currentItem.element()} event_id={this.state.event_id}/>
                    )
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    componentDidMount() {
        this.props.getMenuItems(this.menuItems)
        this.state.loaded = true
        this.forceUpdate()
    }

    renderRoutes = () => {
        if (this.props.menuItems && this.props.menuItems.length) {
            return (
                this.props.menuItems.map((menuItem, index) => {
                    if (menuItem.hasOwnProperty('write') || menuItem.hasOwnProperty('read')) {
                        if (menuItem.write || menuItem.read) {
                            if (menuItem.hasOwnProperty('children')) {
                                return (
                                    menuItem.children.map((child, key) => {
                                        let currentMenuItem = menuItem.children.find(item => item.url === child.url)
                                        return (
                                            <Route path={`/${currentMenuItem.url.split('/')[2]}`} key={key}
                                                   element={currentMenuItem.element()}/>
                                        )
                                    })
                                )
                            } else {
                                let currentMenuItem = this.props.menuItems.find(item => item.url === menuItem.url)
                                return (
                                    <Route path={`/${currentMenuItem.url.split('/')[2]}`} key={index}
                                           element={currentMenuItem.element()}/>
                                )
                            }
                        } else return null
                    } else return null
                })
            )
        } else return null
    }

    render() {
        this.routes = this.renderRoutes()
        return (
            <Routes>
                {this.routes}
                {this.findCurrentRoute()}
            </Routes>
        )
    }
}

export default Administrator
import React, { Fragment } from "react";
import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import "../nix_components/ui_components/controls/controls.scss";

class UF_Permissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.permissions = [
      {
        title: "Agenda Topics",
        field: "agenda",
        read: false,
        write: false,
      },
      {
        title: "Awards",
        field: "award",
        read: false,
        write: false,
      },
      {
        title: "Awards admin",
        field: "awards-admin",
        read: false,
        write: false,
      },
      {
        title: "Detailed exports",
        field: "detailed-export",
        read: false,
        write: false,
      },
      {
        title: "Events",
        field: "event",
        read: false,
        write: false,
      },
      {
        title: "Lunch",
        field: "lunch",
        read: false,
        write: false,
      },
      {
        title: "Media partners",
        field: "media-partner",
        read: false,
        write: false,
      },
      {
        title: "Packages",
        field: "package",
        read: false,
        write: false,
      },
      {
        title: "Registration",
        field: "registration",
        read: false,
        write: false,
        level: 2,
      },
      {
        title: "Speakers",
        field: "speaker",
        read: false,
        write: false,
      },
      {
        title: "Sponsors",
        field: "sponsor",
        read: false,
        write: false,
      },
      {
        title: "Users",
        field: "user",
        read: false,
        write: false,
      },
      {
        title: "Exports",
        field: "export",
        read: false,
        write: false,
      },
      {
        title: "Statistics",
        field: "statistics",
        read: false,
        write: false,
      },
    ];
    this.user_permissions = [];
  }

  onChangeReadyOnly = (item, event) => {
    if (event.target.checked) {
      if (item[`read`] === false) {
        this.user_permissions.push(`${item.field}:read`);
        if (
          this.user_permissions.findIndex(
            (permission) => permission == `${item.field}:write`
          ) > -1
        ) {
          this.user_permissions.splice(
            this.user_permissions.findIndex(
              (permission) => permission == `${item.field}:write`
            ),
            1
          );
        }
        item[`read`] = true;
        item[`write`] = false;
      }
    } else {
      if (item[`read`] === true) {
        this.user_permissions.splice(
          this.user_permissions.findIndex(
            (permission) => permission == `${item.field}:read`
          ),
          1
        );
        item[`read`] = false;
      }
    }
    this.props.onChange(this.user_permissions);
    this.forceUpdate();
  };

  onChangeFullAccess = (item, event) => {
    if (event.target.checked) {
      if (item[`write`] === false) {
        this.user_permissions.push(`${item.field}:write`);
        if (
          this.user_permissions.findIndex(
            (permission) => permission == `${item.field}:read`
          ) > -1
        ) {
          this.user_permissions.splice(
            this.user_permissions.findIndex(
              (permission) => permission == `${item.field}:read`
            ),
            1
          );
        }
        item[`write`] = true;
        item[`read`] = false;
      }
    } else {
      if (item[`write`] === true) {
        this.user_permissions.splice(
          this.user_permissions.findIndex(
            (permission) => permission == `${item.field}:write`
          ),
          1
        );
        item[`write`] = false;
      }
    }
    this.props.onChange(this.user_permissions);
    this.forceUpdate();
  };

  handleUserPermissions = (value) => {
    value.forEach((up) => {
      let field = up.split(":")[0];
      let action = up.split(":")[1];
      this.permissions.find((p) => p.field == field)[action] = true;
    });
    this.forceUpdate();
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.dataItem.is_admin) {
      this.permissions.forEach((scope) => {
        scope.write = true;
        scope.read = true;
        if (
          this.user_permissions.findIndex(
            (permission) => permission == `${scope.field}:write`
          ) < 0
        ) {
          this.user_permissions.push(`${scope.field}:write`);
        }
        if (
          this.user_permissions.findIndex(
            (permission) => permission == `${scope.field}:read`
          ) < 0
        ) {
          this.user_permissions.push(`${scope.field}:read`);
        }
      });
      this.props.onChange(this.user_permissions);
    } else {
      this.permissions.forEach((scope) => {
        scope.write = false;
        scope.read = true;
        if (
          this.user_permissions.findIndex(
            (permission) => permission == `${scope.field}:read`
          ) < 0
        ) {
          this.user_permissions.push(`${scope.field}:read`);
        }
      });
      this.user_permissions = this.user_permissions.filter(
        (permission) => !permission.includes("write")
      );
      this.props.onChange(this.user_permissions);
    }
    return true;
  }

  componentDidMount() {
    if (this.props.value && this.props.value.length) {
      let value = JSON.parse(this.props.value);
      this.user_permissions = value;
      this.handleUserPermissions(value);
    }
    if (this.props.dataItem.is_admin) {
      this.permissions.forEach((scope) => {
        if (
          this.user_permissions.findIndex(
            (permission) => permission == `${scope.field}:write`
          ) < 0
        ) {
          this.user_permissions.push(`${scope.field}:write`);
        }
        if (
          this.user_permissions.findIndex(
            (permission) => permission == `${scope.field}:read`
          ) < 0
        ) {
          this.user_permissions.push(`${scope.field}:read`);
        }
        scope.write = true;
        scope.read = true;
      });
      this.props.onChange(this.user_permissions);
    }
    this.forceUpdate();
  }

  render() {
    return (
      <Fragment>
        <div className={"uf_table_label"}>
          {this.props.label}
          <span className={"uf_table_sublabel"}>
            The system will auto-assign inherited permissions
          </span>
        </div>
        <table className={"uf_table"}>
          <thead>
            <tr>
              <th style={{ width: "60%" }} className={"uf_text_align_left"}>
                Module
              </th>
              <th style={{ width: "20%" }} className={"uf_text_align_left"}>
                Read-only
              </th>
              <th style={{ width: "20%" }} className={"uf_text_align_left"}>
                Full-access
              </th>
            </tr>
          </thead>
          <tbody>
            {this.permissions.map((item) => {
              return (
                <tr key={item.title}>
                  <td>{item.title}</td>
                  <td>
                    <FormControlLabel
                      error={this.props.error.toString()}
                      onChange={(e) => this.onChangeReadyOnly(item, e)}
                      control={<Checkbox checked={item.read} />}
                      label={""}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      error={this.props.error.toString()}
                      onChange={(e) => this.onChangeFullAccess(item, e)}
                      control={<Checkbox checked={item.write} />}
                      label={""}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <FormHelperText style={{ color: this.props.error ? "red" : "inherit" }}>
          {this.props.helperText}
        </FormHelperText>
      </Fragment>
    );
  }
}

export default UF_Permissions;

import data_structure from "./data_structure.json";
import data_types from "./data_types";
import { Tooltip } from "@mui/material";
import React from "react";
import { setValidations, validators } from "./data_validators";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import moment from "moment";
import DescriptionIcon from "@mui/icons-material/Description";

const data_configs = {};
const static_configs = {
  activity_log: {
    ignoreValidation: [""],
    fields: {
      id: {
        label: "ID",
      },
    },
  },
  promo_codes: {
    ignoreValidation: ["id", "created_at", "updated_at", "times_used"],
    fields: {
      id: {
        label: "ID",
        hide: true,
        field: "id",
      },
      packages: {
        hide: false,
        label: "Packages",
        reference: "packages",
        datatype: data_types.reference,
        field: "packages",
        renderCell: (params, reference) => {
          if (!params.hasOwnProperty("valueParsed")) {
            params.valueParsed = JSON.parse(params.value);
            if (!Array.isArray(params.valueParsed)) {
              params.valueParsed = [params.valueParsed];
            }
          }
          params.valueParsed = params.valueParsed.map((c) => reference[c]);
          params.valueParsed = params.valueParsed
            ? params.valueParsed.join(", ")
            : "-";
          return (
            <div style={{ fontSize: "12px" }}>
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre" }}>{params.valueParsed}</div>
                }
              >
                <span style={{ whiteSpace: "pre" }}>{params.valueParsed}</span>
              </Tooltip>
            </div>
          );
        },
      },
      created_by: {
        field: "created_by",
        datatype: data_types.reference,
        reference: "users",
        label: "Created by",
      },
    },
  },
  package_categories: {
    ignoreValidation: ["id", "created_at"],
    fields: {
      id: {
        label: "ID",
        hide: true,
        field: "id",
      },
      published: {
        field: "published",
        label: "Published",
        defaultValue: 1,
      },
      name: {
        label: "Name",
        field: "name",
        validators: [validators.isNull],
      },
      package_id: {
        label: "Package",
        field: "package_id",
        validators: [validators.isNull],
        datatype: data_types.reference,
        reference: "packages",
      },
    },
  },
  package_subcategories: {
    ignoreValidation: ["id", "created_at"],
    fields: {
      id: {
        label: "ID",
        hide: true,
        field: "id",
      },
      name: {
        label: "Name",
        field: "name",
        validators: [validators.isNull],
      },
      package_category_id: {
        label: "Package category",
        field: "package_category_id",
        validators: [validators.isNull],
        datatype: data_types.reference,
        reference: "package_categories",
      },
      package_id: {
        label: "Package",
        field: "package_id",
        validators: [validators.isNull],
        datatype: data_types.reference,
        reference: "packages",
      },
    },
  },
  packages: {
    ignoreValidation: ["id", "ordering", "created_at", "updated_at", "slug"],
    sorting: [{ field: "ordering", sort: "asc" }],
    fields: {
      id: {
        label: "ID",
        hide: true,
      },
      published: {
        field: "published",
        label: "Published",
        defaultValue: 1,
      },
      color: {
        datatype: data_types.color,
        label: "Color",
        maxWidth: 150,
        nullable: false,
      },
      event_id: {
        label: "Event",
        hide: true,
      },
      name: {
        label: "Name",
        align: "left",
        maxWidth: 150,
        renderCell: (params) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                gap: "15px",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: `${
                    params.row.color ? params.row.color : "#FFF"
                  }`,
                  borderRadius: "6px",
                }}
              />
              <div>{params.value}</div>
            </div>
          );
        },
      },
      prices: {
        label: "Prices",
        minWidth: 150,
        renderCell: (params) => {
          if (!params.value || params.value.length == 0) {
            return <div />;
          }

          if (params.hasOwnProperty("valueParsed") == false) {
            try {
              params.valueParsed = JSON.parse(params.value);
            } catch (e) {}
          }

          if (params.valueParsed) {
            return (
              <div style={{ fontSize: "12px" }}>
                <Tooltip
                  title={`Available until ${params.valueParsed.early_bird.expires_at}`}
                >
                  <div
                    style={{
                      color: `${params.row.color ? params.row.color : "black"}`,
                    }}
                  >
                    Early bird: ${params.valueParsed.early_bird.value}
                  </div>
                </Tooltip>
                <Tooltip
                  title={`Available until ${params.valueParsed.regular.expires_at}`}
                >
                  <div
                    style={{
                      color: `${params.row.color ? params.row.color : "black"}`,
                    }}
                  >
                    Standard: ${params.valueParsed.regular.value}{" "}
                  </div>
                </Tooltip>
                <Tooltip
                  title={`Available until ${params.valueParsed.on_spot.expires_at}`}
                >
                  <div
                    style={{
                      color: `${params.row.color ? params.row.color : "black"}`,
                    }}
                  >
                    On spot: ${params.valueParsed.on_spot.value}
                  </div>
                </Tooltip>
              </div>
            );
          }

          return <div />;
        },
        field: "prices",
        nullable: false,
      },
      available_until: {
        label: "Available Until",
        nullable: false,
        field: "available_until",
        renderCell: (params) => {
          return (
            <Tooltip
              title={
                new Date(params.row.available_until).toISOString().split("T")[0]
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <div>
                  {
                    new Date(params.row.available_until)
                      .toISOString()
                      .split("T")[0]
                  }
                </div>
              </div>
            </Tooltip>
          );
        },
      },
      ordering: {
        label: "Ordering",
        maxWidth: 150,
        defaultValue: 0,
      },
      subtitle: {
        label: "Subtitle",
        nullable: false,
      },
      auto_approval: {
        label: "Auto Approval",
        maxWidth: 150,
        nullable: false,
        field: "auto_approval",
      },
      slug: {
        hide: true,
        defaultValue: "",
      },
      created_at: {
        hide: true,
      },
      updated_at: {
        hide: true,
      },
      description: {
        defaultValue: " ",
        hide: true,
        label: "Description",
        field: "description",
        nullable: true,
      },
    },
  },
  registration_categories: {
    ignoreValidation: ["event_id", "id"],
    fields: {
      id: {
        label: "ID",
        hide: true,
        field: "id",
      },
      event_id: {
        label: "Event",
        hide: true,
        field: "event_id",
      },
      name: {
        label: "Name",
        maxWidth: 150,
        field: "name",
      },
      fields: {
        label: "Fields",
        datatype: data_types.json,
        field: "fields",
        renderCell: (params) => {
          const value =
            typeof params.value === "string"
              ? JSON.parse(params.value)
              : params.value;
          const renderToolTip = () => {
            return value.map((field) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignContent: "center",
                  }}
                >
                  <span>Field : {field.field}</span>
                  <span>Optional : {field.optional ? "true" : "false"}</span>
                  <span>Required : {field.required ? "true" : "false"}</span>
                  <span>Hidden : {field.hidden ? "true" : "false"}</span>
                </div>
              );
            });
          };
          return (
            <Tooltip title={renderToolTip()}>
              <IntegrationInstructionsIcon />
            </Tooltip>
          );
        },
      },
    },
  },
  registrations: {
    additionalData: ["notes"],
    ignoreValidation: [
      "id",
      "type",
      "company",
      "category",
      "website",
      "evidence",
      "status",
      "created_at",
      "speaker_id",
      "updated_at",
      "ipn",
      "invoice",
      "sms_code",
      "sponsor_id",
      "invoice_number",
    ],
    sorting: [{ field: "created_at", sort: "desc" }],
    fields: {
      id: {
        hide: true,
      },
      sponsor_id: {
        hide: true,
        field: "sponsor_id",
        label: "Sponsor / Exhibitor",
      },
      event_id: {
        hide: true,
      },
      type: {
        hide: true,
      },
      created_at: {
        hide: false,
        label: "Created at",
      },
      updated_at: {
        hide: true,
      },
      invoice: {
        hide: false,
      },
      ipn: {
        hide: true,
        defaultValue: " ",
      },
      first_name: {
        label: "Name",
        field: "first_name",
        flex: 1.1,
        align: "left",
        renderCell: (params) => {
          return (
            <Tooltip
              title={`${params.row.first_name ? params.row.first_name : ""} ${
                params.row.last_name ? params.row.last_name : ""
              }`}
            >
              <div>
                {params.row.first_name ? params.row.first_name : ""}{" "}
                {params.row.last_name ? params.row.last_name : ""}
                <br />
                <a
                  style={{
                    textDecoration: "none",
                    fontSize: "10px",
                    color: "rgba(34, 42, 96, 1)",
                  }}
                  href={`mailto:${params.row.email ? params.row.email : ""}`}
                >
                  {params.row.email ? params.row.email : ""}
                </a>
              </div>
            </Tooltip>
          );
        },
      },
      last_name: {
        label: "Last Name",
      },
      email: {
        label: "Email",
        validators: [validators.isNotEmail],
      },
      company: {
        label: "Company",
      },
      category: {
        label: "Category",
        field: "category",
        reference: "package_categories",
        datatype: data_types.reference,
        renderCell: (params, reference) => {
          return (
            <Tooltip title={reference[params.value]}>
              <div style={{ fontSize: "12px" }}>{reference[params.value]}</div>
            </Tooltip>
          );
        },
      },
      website: {
        label: "Website",
        field: "website",
        websitePreview: true,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <a
                target="_blank"
                href={`${params.value}`}
                style={{ textDecoration: "auto" }}
              >
                {params.value}
              </a>
            </Tooltip>
          );
        },
      },
      phone: {
        label: "Phone",
      },
      ticket_quantity: {
        label: "Tickets",
        field: "ticket_quantity",
        // renderCell: (params) => {
        //     return (
        //         <Tooltip title={`${params.value ? params.value : 0} (${params.row.attendees_completed})`}>
        //             <a
        //                 style={{cursor: 'pointer', textDecoration : 'none'}}>
        //                     {params.value ? params.value : 0} ({params.row.attendees_completed})
        //             </a>
        //         </Tooltip>
        //     )
        // }
      },
      paid: {
        label: "Paid",
      },
      notes: {
        label: "Notes",
        field: "notes",
        hide: true,
        // renderCell: (params) => {
        //     return (
        //         params.row.notes !== undefined && params.row.notes !== null ?
        //             <Tooltip title={`${params.row.notes}`}>
        //                 <NotesIcon/>
        //             </Tooltip>
        //             : null
        //     )
        // }
      },
      evidence: {
        label: "Evidence",
        field: "evidence",
        renderCell: (params) => {
          return (
            <Tooltip title={`${window.location.origin}/api/${params.value}`}>
              <a
                style={{ textDecoration: "none" }}
                href={`${window.location.origin}/api/${params.value}`}
                target="_blank"
              >
                {params.value ? <DescriptionIcon /> : "-"}
              </a>
            </Tooltip>
          );
        },
      },
      package_id: {
        label: "Package",
        reference: "packages",
        datatype: data_types.reference,
        renderCell: (params, reference) => {
          return (
            <Tooltip title={reference ? reference[params.value] : ""}>
              <div style={{ fontSize: "12px" }}>
                {reference ? reference[params.value] : ""}
              </div>
            </Tooltip>
          );
        },
        filterable: true,
      },
      subcategory: {
        field: "subcategory",
        datatype: data_types.reference,
        reference: "package_subcategories",
        label: "Subcategory",
        hide: true,
      },
      status: {
        label: "Status",
        reference: "registrationStatuses",
        filterable: true,
        renderCell: (params) => {
          return (
            <Tooltip
              title={
                params.value === "Pending confirmation"
                  ? global.UF.EVENT_SETTINGS.PENDING_CONFIRMATION_LABEL
                  : params.value
              }
            >
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "12px",
                  borderRadius: "10px",
                  color: `${
                    params.value.toLowerCase() === "rejected"
                      ? "#EC5F5F"
                      : params.value.toLowerCase() === "completed"
                      ? "#82D73F"
                      : "#F2AF5C"
                  }`,
                  textTransform: "capitalize",
                }}
              >
                •{" "}
                {params.value === "Pending confirmation"
                  ? global.UF.EVENT_SETTINGS.PENDING_CONFIRMATION_LABEL
                  : params.value}
              </div>
            </Tooltip>
          );
        },
      },
    },
  },
  registration_attendee: {
    ignoreValidation: ["id", "registration_id"],
    listFields: ["sponsor_types"],
    sorting: [{ field: "updated_at", sort: "desc" }],
    fields: {
      first_name: {
        label: "Name",
        align: "left",
        field: "first_name",
        flex: 1.1,
        renderCell: (params) => {
          return (
            <Tooltip
              title={`${params.row.first_name ? params.row.first_name : ""} ${
                params.row.last_name ? params.row.last_name : ""
              }`}
            >
              <div>
                {params.row.first_name ? params.row.first_name : ""}{" "}
                {params.row.last_name ? params.row.last_name : ""}
                <br />
                <a
                  style={{
                    textDecoration: "none",
                    fontSize: "10px",
                    color: "rgba(34, 42, 96, 1)",
                  }}
                  href={`mailto:${params.row.email ? params.row.email : ""}`}
                >
                  {params.row.email ? params.row.email : ""}
                </a>
              </div>
            </Tooltip>
          );
        },
      },
      id: {
        hide: true,
      },
      package_id: {
        field: "package_id",
        label: "Package",
        reference: "packages",
        datatype: data_types.reference,
        filterable: true,
      },
      sponsor_types: {
        field: "sponsor_types",
        label: "Sponsor Types",
        datatype: "reference",
        reference: "sponsor_types",
        renderCell: (params, reference) => {
          if (!params.hasOwnProperty("valueParsed")) {
            params.valueParsed = JSON.parse(params.value);
            if (!Array.isArray(params.valueParsed)) {
              params.valueParsed = [params.valueParsed];
            }
          }
          params.valueParsed = params.valueParsed.map((c) => reference[c]);
          params.valueParsed = params.valueParsed
            ? params.valueParsed.join("\r\n")
            : "-";
          return (
            <div style={{ fontSize: "12px" }}>
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre" }}>{params.valueParsed}</div>
                }
              >
                <span style={{ whiteSpace: "pre" }}>{params.valueParsed}</span>
              </Tooltip>
            </div>
          );
        },
      },
      booth_numbers: {
        field: "booth_numbers",
        label: "Booth Numbers",
        datatype: data_types.string,
      },
      package: {
        field: "package",
        label: "Package",
      },
      lunch_voucher: {
        hide: true,
      },
      qr_code: {
        hide: true,
      },
      created_at: {
        hide: true,
      },
      updated_at: {
        hide: false,
      },
      unique_code: {
        hide: true,
      },
      activation_token: {
        hide: true,
      },
      nationality: {
        hide: true,
      },
      registration_id: {
        label: "Registration",
      },
      birth_date: {
        hide: true,
      },
      active: {
        filterable: true,
        reference: "attendeeActive",
        renderCell: (params) => {
          return (
            <Tooltip title={params.value === 1 ? "Yes" : "No"}>
              <div>{params.value === 1 ? "Yes" : "No"}</div>
            </Tooltip>
          );
        },
      },
      status: {
        filterable: true,
        reference: "attendeesStatuses",
        renderCell: (params) => {
          return (
            <Tooltip
              title={
                params.value === "Pending confirmation"
                  ? global.UF.EVENT_SETTINGS.PENDING_CONFIRMATION_LABEL
                  : params.value
              }
            >
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "12px",
                  borderRadius: "10px",
                  color: `${
                    params.value === "rejected"
                      ? "#EC5F5F"
                      : params.value.toLowerCase() === "approved"
                      ? "#82D73F"
                      : "#F2AF5C"
                  }`,
                  textTransform: "capitalize",
                }}
              >
                •{" "}
                {params.value === "Pending confirmation"
                  ? global.UF.EVENT_SETTINGS.PENDING_CONFIRMATION_LABEL
                  : params.value}
              </div>
            </Tooltip>
          );
        },
      },
    },
  },
  sponsor_types: {
    ignoreValidation: ["id", "created_at", "updated_at"],
    sorting: [{ field: "name", sort: "asc" }],
    fields: {
      id: {
        hide: true,
      },
      event_id: {
        hide: true,
      },
      updated_at: {
        hide: true,
      },
      created_at: {
        label: "Created_at",
      },
    },
  },
  sponsor_categories: {
    ignoreValidation: ["id", "created_at", "updated_at"],
    sorting: [{ field: "name", sort: "asc" }],
    fields: {
      id: {
        hide: true,
      },
      event_id: {
        hide: true,
      },
      updated_at: {
        hide: true,
      },
      created_at: {
        label: "Created_at",
      },
    },
  },
  sponsors: {
    ignoreValidation: [
      "id",
      "event_id",
      "created_at",
      "updated_at",
      "registration_id",
      "booth_numbers",
      "phone",
    ],
    sorting: [{ field: "created_at", sort: "desc" }],
    fields: {
      id: {
        hide: true,
      },
      ordering: {
        defaultValue: 1,
      },
      event_id: {
        hide: true,
      },
      name: {
        align: "left",
        label: "Company Name",
        flex: 1.3,
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.name}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                {params.row.logo ? (
                  <img
                    src={`/api/files/${params.row.logo}`}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                ) : null}
                <Tooltip title={`${params.row.name ? params.row.name : ""}`}>
                  <div>
                    {params.row.name ? params.row.name : ""}
                    <br />
                    <a
                      style={{
                        textDecoration: "none",
                        fontSize: "10px",
                        color: "rgba(34, 42, 96, 1)",
                      }}
                      href={`mailto:${
                        params.row.email ? params.row.email : ""
                      }`}
                    >
                      {params.row.email ? params.row.email : ""}
                    </a>
                  </div>
                </Tooltip>
              </div>
            </Tooltip>
          );
        },
      },
      country: {
        hide: true,
        nullable: true,
        defaultValue: "",
      },
      email: {
        label: "Company Email",
      },
      phone: {
        hide: true,
        defaultValue: "",
        validators: [],
      },
      website: {
        hide: true,
        websitePreview: true,
      },
      booth_numbers: {
        label: "Booth(s)",
        defaultValue: [],
        registration_id: {
          label: "Registration",
          reference: "registrations",
          datatype: data_types.reference,
        },
        renderCell: (params) => {
          let parsedValue =
            typeof params.value == "string"
              ? JSON.parse(params.value)
              : params.value;
          parsedValue = parsedValue != null ? parsedValue.join(", ") : "";
          return parsedValue.length ? (
            <Tooltip title={<div>{parsedValue}</div>}>
              <span>{parsedValue}</span>
            </Tooltip>
          ) : (
            <span>-</span>
          );
        },
      },
      description: {
        hide: true,
        defaultValue: " ",
        nullable: true,
      },
      logo: {
        hide: true,
        validators: [validators.isNull],
      },
      logo_white: {
        hide: true,
      },
      updated_at: {
        hide: true,
      },
      types: {
        align: "left",
        label: "Sponsorship Type(s)",
        datatype: data_types.reference,
        reference: "sponsor_types",
        renderCell: (params, reference) => {
          if (!params.hasOwnProperty("valueParsed")) {
            params.valueParsed = JSON.parse(params.value);
            if (!Array.isArray(params.valueParsed)) {
              params.valueParsed = [params.valueParsed];
            }
          }
          params.valueParsed = params.valueParsed.map((c) => reference[c]);
          params.valueParsed = params.valueParsed
            ? params.valueParsed.join("\r\n")
            : "-";
          return (
            <div style={{ fontSize: "12px" }}>
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre" }}>{params.valueParsed}</div>
                }
              >
                <span style={{ whiteSpace: "pre" }}>{params.valueParsed}</span>
              </Tooltip>
            </div>
          );
        },
      },
      category_id: {
        label: "Sponsorship Category",
        reference: "sponsor_categories",
        datatype: data_types.reference,
      },
      registration_id: {
        hide: true,
      },
      contact_name: {
        hide: true,
        nullable: true,
        defaultValue: "",
      },
      contact_email: {
        hide: true,
      },
    },
  },
  speakers: {
    ignoreValidation: [
      "id",
      "event_id",
      "ordering",
      "created_at",
      "updated_at",
      "registration_id",
    ],
    sorting: [{ field: "created_at", sort: "desc" }],
    fields: {
      id: {
        hide: true,
      },
      first_name: {
        flex: 1.3,
        align: "left",
        field: "first_name",
        renderCell: (params) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              {params.row.image ? (
                <img
                  src={`/api/files/${params.row.image}`}
                  style={{
                    width: "50px",
                    height: "50px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              ) : null}
              <Tooltip
                title={`${params.row.first_name ? params.row.first_name : ""} ${
                  params.row.last_name ? params.row.last_name : ""
                }`}
              >
                <div>
                  {params.row.first_name ? params.row.first_name : ""}{" "}
                  {params.row.last_name ? params.row.last_name : ""}
                  <br />
                  <a
                    style={{
                      textDecoration: "none",
                      fontSize: "10px",
                      color: "rgba(34, 42, 96, 1)",
                    }}
                    href={`mailto:${params.row.email ? params.row.email : ""}`}
                  >
                    {params.row.email ? params.row.email : ""}
                  </a>
                </div>
              </Tooltip>
            </div>
          );
        },
      },
      ordering: {
        field: "ordering",
        defaultValue: 1,
        hide: true,
      },
      event_id: {
        hide: true,
      },
      registration_id: {
        hide: true,
      },
      updated_at: {
        hide: true,
      },
      created_at: {
        label: "Created At",
      },
      image: {
        hide: true,
      },
      bio: {
        hide: true,
      },
      position: {
        label: "Job title",
      },
    },
  },
  media_partners: {
    ignoreValidation: [
      "id",
      "event_id",
      "ordering",
      "created_at",
      "updated_at",
    ],
    sorting: [{ field: "ordering", sort: "asc" }],
    fields: {
      id: {
        hide: true,
      },
      name: {
        align: "left",
        field: "name",
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.name}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                {params.row.logo ? (
                  <img
                    src={`/api/files/${params.row.logo}`}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                ) : null}
                <div>{params.row.name}</div>
              </div>
            </Tooltip>
          );
        },
      },
      ordering: {
        defaultValue: 1,
      },
      event_id: {
        hide: true,
      },
      created_at: {
        label: "Created At",
      },
      logo: {
        hide: true,
      },
      updated_at: {
        hide: true,
      },
    },
  },
  schedule_types: {
    ignoreValidation: ["id"],
    sorting: [{ field: "created_at", sort: "desc" }],
    fields: {
      id: {
        field: "id",
        label: "ID",
        hide: true,
      },
      name: {
        field: "name",
        label: "Name",
        validators: [validators.isNull],
      },
    },
  },
  agenda_topics: {
    ignoreValidation: [
      "id",
      "event_id",
      "created_at",
      "updated_at",
      "moderators",
      "speakers",
      "image",
    ],
    sorting: [{ field: "created_at", sort: "desc" }],
    fields: {
      id: {
        hide: true,
      },
      title: {
        align: "left",
        field: "title",
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.title}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                {params.row.image !== "" ? (
                  <img
                    src={`/api/files/${params.row.image}`}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                ) : null}
                <div>{params.row.title}</div>
              </div>
            </Tooltip>
          );
        },
      },
      published: {
        field: "published",
        defaultValue: 1,
        label: "Published",
      },
      moderators: {
        align: "left",
        reference: "speakers",
        datatype: data_types.reference,
        renderCell: (params, reference) => {
          if (!params.hasOwnProperty("valueParsed")) {
            params.valueParsed = JSON.parse(params.value);
            if (!Array.isArray(params.valueParsed)) {
              params.valueParsed = [params.valueParsed];
            }
          }
          params.valueParsed = params.valueParsed.map((c) => reference[c]);
          params.valueParsed = params.valueParsed
            ? params.valueParsed.join("\r\n")
            : "-";
          return (
            <div style={{ fontSize: "12px" }}>
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre" }}>{params.valueParsed}</div>
                }
              >
                <span style={{ whiteSpace: "pre" }}>{params.valueParsed}</span>
              </Tooltip>
            </div>
          );
        },
        defaultValue: [],
      },
      speakers: {
        align: "left",
        reference: "speakers",
        datatype: data_types.reference,
        renderCell: (params, reference) => {
          if (!params.hasOwnProperty("valueParsed")) {
            params.valueParsed = JSON.parse(params.value);
            if (!Array.isArray(params.valueParsed)) {
              params.valueParsed = [params.valueParsed];
            }
          }
          params.valueParsed = params.valueParsed.map((c) => reference[c]);
          params.valueParsed = params.valueParsed
            ? params.valueParsed.join("\r\n")
            : "-";
          return (
            <div style={{ fontSize: "12px" }}>
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre" }}>{params.valueParsed}</div>
                }
              >
                <span style={{ whiteSpace: "pre" }}>{params.valueParsed}</span>
              </Tooltip>
            </div>
          );
        },
        defaultValue: [],
      },
      ordering: {
        defaultValue: 1,
      },
      event_id: {
        hide: true,
      },
      description: {
        hide: true,
      },
      updated_at: {
        hide: true,
      },
      date: {
        align: "center",
        flex: 1.3,
        label: "Date",
        renderCell: (params) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <div>{moment(params.value).format("MM/DD/YYYY")}</div>
              <div
                style={{
                  color: "rgba(34, 42, 96, 0.60)",
                  textAlign: "center",
                }}
              >
                {params.row.start_time
                  ? moment(params.row.start_time).format("HH:mm:ss")
                  : ""}
                -{" "}
                {params.row.end_time
                  ? moment(params.row.end_time).format("HH:mm:ss")
                  : "Not defined"}
              </div>
            </div>
          );
        },
      },
      image: {
        hide: true,
        defaultValue: "",
      },
      start_time: {
        label: "Start Time",
        timezone: "UTC",
      },
      end_time: {
        label: "End Time",
        timezone: "UTC",
      },
    },
  },
  lunch_voucher_scans: {
    ignoreValidation: [""],
    sorting: [{ field: "scan_date", sort: "desc" }],
    fields: {
      id: {
        label: "Tracker ID",
      },
      event_id: {
        hide: true,
      },
      lunch_voucher: {
        label: "Voucher",
      },
      created_at: {
        hide: true,
      },
      updated_at: {
        hide: true,
      },
    },
  },
  users: {
    ignoreValidation: [
      "id",
      "activation_token",
      "forgot_password_token",
      "active",
      "created_at",
      "updated_at",
      "password",
      "password2",
    ],
    fields: {
      id: {
        hide: true,
      },
      active: {
        field: "active",
        label: "Active",
        defaultValue: 1,
      },
      width: 200,
      email: {
        field: "email",
        label: "Email",
        renderCell: (params) => {
          return (
            <Tooltip title={params.value}>
              <div>
                <span>{params.value}</span>
                {params.id === JSON.parse(localStorage.getItem("user")).id ? (
                  <span className={"q-chip"}>You</span>
                ) : null}
              </div>
            </Tooltip>
          );
        },
      },
      updated_at: {
        hide: true,
      },
      password: {
        hide: true,
        defaultValue: " ",
      },
      password2: {
        hide: true,
        defaultValue: " ",
      },
      activation_token: {
        hide: true,
      },
      forgot_password_token: {
        hide: true,
      },
      scopes: {
        label: "Permissions",
        defaultValue: [],
      },
      is_admin: {
        label: "System Admin",
      },
    },
  },
  awards: {
    ignoreValidation: [
      "id",
      "status",
      "created_at",
      "updated_at",
      "nomination_limit",
      "logo",
    ],
    fields: {
      id: {
        hide: true,
      },
      logo: {
        field: "logo",
        hide: true,
      },
      status: {
        field: "status",
        filterable: true,
        reference: "awardsStatuses",
        renderCell: (params) => {
          return (
            <Tooltip
              title={
                params.value === "Pending confirmation"
                  ? global.UF.EVENT_SETTINGS.PENDING_CONFIRMATION_LABEL
                  : params.value
              }
            >
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "12px",
                  borderRadius: "10px",
                  color: `${
                    params.value === "rejected"
                      ? "#EC5F5F"
                      : params.value.toLowerCase() === "winning"
                      ? "#82D73F"
                      : "#F2AF5C"
                  }`,
                  textTransform: "capitalize",
                }}
              >
                •{" "}
                {params.value === "Pending confirmation"
                  ? global.UF.EVENT_SETTINGS.PENDING_CONFIRMATION_LABEL
                  : params.value}
              </div>
            </Tooltip>
          );
        },
      },
      name: {
        field: "name",
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.name}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                {params.row.logo ? (
                  <img
                    src={`/api/files/${params.row.logo}`}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                ) : null}
                <div>{params.row.name}</div>
              </div>
            </Tooltip>
          );
        },
      },
      updated_at: {
        hide: true,
      },
    },
  },
  events: {
    ignoreValidation: ["id", "read_only", "created_at", "updated_at"],
    fields: {
      id: {
        hide: false,
      },
      read_only: {
        hide: true,
      },
      updated_at: {
        hide: true,
      },
      start_date: {
        field: "start_date",
        label: "Start date",
      },
      end_date: {
        field: "end_date",
        label: "End date",
        validators: [
          {
            msg: `Date can't be before today`,
            func: (value, dataItem) => {
              if (dataItem[`start_date`] !== null && value !== null) {
                return (
                  new Date(dataItem[`start_date`]).getTime() + 86400 >
                  new Date(value).getTime()
                );
              } else {
                return true;
              }
            },
          },
        ],
      },
      vip_registrations_close_date: {
        field: "vip_registrations_close_date",
        label: "Vip registrations close date",
        validators: [validators.isNull],
      },
      regular_registrations_close_date: {
        field: "regular_registrations_close_date",
        label: "Regular registrations close date",
        validators: [validators.isNull],
      },
    },
  },
  event_settings: {
    ignoreValidation: ["id", "created_at", "updated_at", "event_id"],
    fields: {
      id: {
        hide: true,
      },
      event_id: {
        field: "event_id",
        label: "Event",
        datatype: data_types.reference,
        reference: "events",
      },
      setting_key: {
        field: "setting_key",
        label: "Setting",
        flex: 2,
      },
      setting_value: {
        field: "setting_value",
        label: "Value",
      },
    },
  },
  categories: {
    ignoreValidation: ["id", "award_id", "created_at", "updated_at"],
    fields: {
      id: {
        hide: true,
      },
      award_id: {
        hide: true,
      },
      updated_at: {
        hide: true,
      },
    },
  },
  nominees: {
    ignoreValidation: [
      "id",
      "categories",
      "status",
      "deleted",
      "votes",
      "created_at",
    ],
    fields: {
      id: {
        hide: true,
      },
      logo_path: {
        hide: true,
      },
      url: {
        field: "url",
        label: "Website",
        websitePreview: true,
        validators: [validators.isWebsite],
        hide: true,
      },
      deleted: {
        hide: true,
      },
      updated_at: {
        hide: true,
      },
      name: {
        label: "Name",
        align: "left",
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.name}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                {params.row.logo_path ? (
                  <img
                    src={`/api/files/${params.row.logo_path}`}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                ) : null}

                <div>{params.row.name}</div>
              </div>
            </Tooltip>
          );
        },
      },
      award_id: {
        field: "award_id",
        label: "Award",
        hide: true,
      },
      nomination_id: {
        reference: "nominations",
        flex: 2,
        datatype: data_types.reference,
        label: "Nominations",
        field: "nomination_id",
        renderCell: (params, reference) => {
          if (!params.hasOwnProperty("valueParsed")) {
            params.valueParsed = JSON.parse(params.value);
            if (!Array.isArray(params.valueParsed)) {
              params.valueParsed = [params.valueParsed];
            }
          }
          params.valueParsed = params.valueParsed.map((c) => reference[c]);
          params.valueParsed = params.valueParsed
            ? params.valueParsed.join("\r\n")
            : "-";
          return (
            <div style={{ fontSize: "12px" }}>
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre" }}>{params.valueParsed}</div>
                }
              >
                <span style={{ whiteSpace: "pre" }}>{params.valueParsed}</span>
              </Tooltip>
            </div>
          );
        },
      },
    },
  },
  nominations: {
    ignoreValidation: [
      "id",
      "award_id",
      "ordering",
      "description",
      "winner_id",
      "created_at",
      "logo_path",
    ],
    fields: {
      id: {
        hide: true,
      },
      award_id: {
        field: "award_id",
        // datatype: data_types.reference,
        // reference: "awards",
        label: "Award",
      },
      category_id: {
        field: "category_id",
        datatype: data_types.reference,
        reference: "categories",
        label: "Category",
        validators: [validators.isNull],
      },
      name: {
        field: "name",
        datatype: data_types.string,
        label: "Name",
        align: "left",
        validators: [validators.isNull, validators.isEmpty],
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.name}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                {params.row.logo_path ? (
                  <img
                    src={`/api/files/${params.row.logo_path}`}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                ) : null}

                <div>{params.row.name}</div>
              </div>
            </Tooltip>
          );
        },
      },
      description: {
        field: "description",
        label: "Description",
        multiline: true,
      },
      logo_path: {
        field: "logo_path",
        label: "Logo",
        datatype: data_types.string,
      },
      ordering: {
        field: "ordering",
        label: "Ordering",
      },
      winner_id: {
        field: "winner_id",
        label: "Winner",
      },
    },
  },
};

const parseDataType = (name, type) => {
  if (type.indexOf("char") > -1) {
    return data_types.string;
  }

  if (type.indexOf("enum") > -1) {
    return data_types.string;
  }

  switch (type) {
    case "timestamp":
      return data_types.time;
    case "date":
      return data_types.date;
    case "datetime":
      return data_types.datetime;
    case "time":
      return data_types.time;
    case "int":
      return data_types.number;
    case "tinyint(1)":
      return data_types.boolean;
    case "json":
      return data_types.json;
    case "text":
    case "longtext":
      return data_types.string;
  }

  if (
    type.indexOf("int") > -1 ||
    type.indexOf("float") > -1 ||
    type.indexOf("long") > -1 ||
    type.indexOf("decimal") > -1
  ) {
    return data_types.number;
  }

  console.error(`New datatype for field : ${name} that is type : ${type}`);
  return data_types.string;
};

const capitalizeFirstLetter = (string) => {
  if (string.length) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

const generateFieldsName = (label) => {
  if (label.includes("_")) {
    return `${capitalizeFirstLetter(
      label.split("_")[0]
    )} ${capitalizeFirstLetter(label.split("_")[1])}`;
  } else {
    return capitalizeFirstLetter(label);
  }
};

const handleCustomRenderCell = (field) => {
  if (!field.hasOwnProperty("renderCell")) {
    if (field.Field.toLowerCase().includes("email")) {
      field.renderCell = (params) => {
        return (
          <a style={{ textDecoration: "none" }} href={`mailto:${params.value}`}>
            {params.value}
          </a>
        );
      };
    }
    if (field.Field.toLowerCase().includes("phone")) {
      field.renderCell = (params) => {
        return (
          <a style={{ textDecoration: "none" }} href={`tel:${params.value}`}>
            {params.value}
          </a>
        );
      };
    }
  }
};

Object.keys(data_structure).forEach((table) => {
  data_configs[table] = {};
  let fields = data_structure[table].fields;
  let curConfig = data_configs[table];
  curConfig.fields = {};
  curConfig.fieldsArr = [];
  if (static_configs[table]) {
    curConfig.ignoreValidation = static_configs[table].ignoreValidation;
    curConfig.sorting = static_configs[table].sorting;
    curConfig.additionalData = static_configs[table].additionalData;
  }

  fields.forEach((field) => {
    let curField = {
      ...field,
      field: field.Field,
      name: field.Field,
      label: generateFieldsName(field.Field),
      nullable: field.Null != "NO",
      datatype: parseDataType(field.Field, field.Type),
    };

    handleCustomRenderCell(curField);

    if (static_configs[table] && static_configs[table].fields[field["Field"]]) {
      curField = Object.assign(
        curField,
        static_configs[table].fields[field["Field"]]
      );
    }
    if (
      curField.hasOwnProperty("defaultValue") == false &&
      curField.datatype == data_types.boolean
    ) {
      curField.defaultValue = false;
    }
    if (
      curField.hasOwnProperty("defaultValue") == false &&
      (curField.datatype == data_types.date ||
        curField.datatype == data_types.datetime)
    ) {
      let date = new Date();
      curField.timezone = "UTC";
      curField.defaultValue = `${date.getUTCFullYear()}-${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
    }
    if (curField.datatype == "time") {
      curField.timezone = "UTC";
    }
    if (curField.field == "updated_at") {
      curField.defaultValue = null;
    }
    if (
      curField.hasOwnProperty("defaultValue") == false &&
      curField.datatype == data_types.color
    ) {
      curField.defaultValue = "#000";
    }
    curConfig.fields[field["Field"]] = curField;
    curConfig.fieldsArr.push(curField);
  });
  setValidations(data_configs[table]);
});

export default data_configs;

import React, { Fragment } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import withRouter from "../../../../nix_components/components/withRouter";
import data_types from "../../../../nix_components/data/data_types";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { IconButton } from "@mui/material";
import ExportNomineesModal from "../../../../components/modals/ExportNomineesModal";
class Nominees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: "award",
      scopeCondition: false,
      loaded: false,
      export: false,
    };
  }

  tools = {
    export: () => {
      return this.state.scopeCondition ? (
        <Tooltip title={"Export"} placement="bottom-start">
          <IconButton
            aria-label="export"
            color="default"
            onClick={() => this.setState({ export: true })}
          >
            <DriveFolderUploadIcon />
          </IconButton>
        </Tooltip>
      ) : null;
    },
  };

  buttons = {};

  formConfig = {
    rows: [
      ["name", "email", "nominated_by"],
      [
        {
          label: "Website",
          field: "url",
          datatype: data_types.string,
        },
        {
          label: "Status",
          field: "status",
          reference: "nomineesStatuses",
          datatype: data_types.reference,
        },
      ],
      [
        {
          label: "Nominations",
          reference: "nominations",
          datatype: data_types.reference,
          field: "nomination_id",
          multiple: true,
        },
        {
          label: "Logo",
          field: "logo_path",
          datatype: data_types.file,
        },
      ],
      [
        {
          label: "Description",
          field: "description",
          multiline: true,
          datatype: data_types.string,
        },
      ],
      [
        {
          label: "Eligibility",
          field: "eligibility",
          multiline: true,
          datatype: data_types.string,
        },
      ],
    ],
  };

  renderExportNomineesModal = () => {
    return (
      <ExportNomineesModal
        open={this.state.export}
        onClose={() => this.setState({ export: false })}
      />
    );
  };

  handleBeforeAddNew = (dataItem, callback) => {
    dataItem.award_id = global.UF.award_id;
    if (callback) {
      callback();
    }
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  componentDidMount() {
    this.findCurrentPermission();
  }

  gridProps = {
    fields: ["name", "nomination_id", "award_id", "status"],
  };

  render() {
    return (
      <Fragment>
        {this.state.export && this.renderExportNomineesModal()}
        <UF_Content
          table="nominees"
          title="Nominees"
          id={this.props.award_id}
          permission="award"
          buttons={this.buttons}
          edit={this.state.scopeCondition}
          tools={this.tools}
          add={this.state.scopeCondition}
          sync={this.state.scopeCondition}
          export={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          formConfig={this.formConfig}
          beforeAddNew={this.handleBeforeAddNew}
          gridProps={this.gridProps}
          rowHeight={100}
        />
      </Fragment>
    );
  }
}

export default withRouter(Nominees);

import React, {Component} from 'react'
import {Dialog, Typography} from '@mui/material'
import PropTypes from 'prop-types';
import UF_Button from "../../nix_components/ui_components/controls/nx_button.js";
import Nx_Ctrl_TextInput from "../../nix_components/ui_components/controls/nx_ctrl_textinput";
import './set_password_modal.scss'

class SetEmailTemplateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            template_name: null,
            isError : false,
            errMsg : ''
        }
    }

    setEmailTemplate = () => {
        this.state.isError = this.handleValidation();
        if(!this.state.isError && this.props.event_id !== null) {
            this.state.errMsg = ''
            let tempEmailTemplate = global.UF.dataProvider.datastructure['email_templates'].new();
            tempEmailTemplate.event_id = this.props.event_id !== null ? this.props.event_id : null
            tempEmailTemplate.template_name = this.state.template_name;
            tempEmailTemplate.Save(() => {
                this.props.onClose();
            })
        }
        this.forceUpdate()
    }

    handleValidation = () => {
        let valid = false;
        if(this.state.template_name !== null) {
            valid = this.state.template_name.trim().length
        }
        if(!valid) {
            this.state.errMsg = 'Field is required';
        }
        this.forceUpdate();
        return !valid
    }

    onChange = (val, field) => {
        this.state[field] = val;
        this.forceUpdate()
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                keepMounted
                onClose={this.props.onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className="nx_modal_wrapper">
                    <div className="nx_modal_title">
                        <Typography variant='h5'>Set email template</Typography>
                    </div>
                    <div>
                        <Nx_Ctrl_TextInput
                            onChange={(val) => this.onChange(val, `template_name`)}
                            type={"text"}
                            label={"Template name"}
                            error={this.state.isError}
                            helperText={this.state.errMsg}
                            value={this.state.template_name}
                        />
                    </div>
                    <div className="nx_buttons">
                        <UF_Button onClick={this.props.onClose} variant={'outlined'} label={'Cancel'}/>
                        <UF_Button onClick={this.setEmailTemplate} label={'Save'}/>
                    </div>
                </div>
            </Dialog>
        )
    }
}

SetEmailTemplateModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
};

export default SetEmailTemplateModal
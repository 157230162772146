import '../nx_form.scss'

const UI_Form = (props) => {
    return <div className="uf_form">
        {props.children}
    </div>
}

const UI_Form_Row = (props) => {
    return <div className={`uf_form_row ${props.alignment ? props.alignment : ''}`}>
        {props.children}
    </div>
}
const UF_Form_Cell = (props) => {
    const width = (100 / props.cols) + '%'
    return <div style={{width}}>
        {props.children}
    </div>
}


export {UI_Form, UI_Form_Row, UF_Form_Cell}

import React from "react";
import Grid from "../../layouts/Grid/Grid";
import withRouter from "../../../components/withRouter";
import HeaderItem from "./controls/HeaderItem";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LogoutIcon from "@mui/icons-material/Logout";
import "./header.scss";

const Header = (props) => {
  const headerConfig = [
    {
      title: "Events",
      icon: <CalendarMonthIcon />,
      items: props.eventItems.length ? props.eventItems : [],
    },
    {
      title: "Awards",
      icon: <EmojiEventsIcon />,
      items: props.awardItems.length ? props.awardItems : [],
    },
    {
      title: "Administrator",
      icon: <AdminPanelSettingsIcon />,
      onClick: () => {
        props.router.navigate("/administrator");
      },
    },
    {
      title: "Log out",
      icon: <LogoutIcon />,
      onClick: () => logOut(),
    },
  ];

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  const renderHeaderItems = () => {
    return headerConfig.map((item, key) => {
      return (
        <HeaderItem
          key={key}
          title={item.title}
          items={item.hasOwnProperty("items") ? item.items : null}
          icon={item.icon}
          onClick={item.hasOwnProperty("onClick") ? item.onClick : null}
        />
      );
    });
  };

  return (
    <Grid
      className="header_container"
      style={{
        width: "35%",
        marginRight: "50px",
        justifySelf: "flex-end",
        alignItems: "center",
        gridAutoFlow: "column",
        overflowY: "auto",
      }}
    >
      {renderHeaderItems()}
    </Grid>
  );
};

export default withRouter(Header);

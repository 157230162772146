import React, { Fragment } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import withRouter from "../../../../components/withRouter";
import "../header.scss";
import Button from "@mui/material/Button/Button";

class HeaderItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false,
      activeItem: null,
    };
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  handleActiveItem = async (item) => {
    this.props.items.forEach((item) => (item.selected = false));
    item.selected = true;
    if (item.hasOwnProperty("event_id")) {
      global.UF.event_id = item.event_id;
      global.UF.data.currentEvent = item;
      await this.handleCurrentEvent(item.event_id);
    } else if (item.hasOwnProperty("award_id")) {
      global.UF.award_id = item.award_id;
      global.UF.data.currentAward = item;
    }
    if (item.url) {
      this.props.router.navigate(`/${item.url}`);
    }
    if (item.onClick) {
      item.onClick();
    }
    this.handleClose();
  };

  handleCurrentEvent = async (event_id) => {
    try {
      const EVENT_SETTINGS = await global.UF.makeRequest_v2(
        `POST`,
        `/api/init`,
        {
          event_id: event_id,
          force: true,
        },
        true
      );
      global.UF.EVENT_SETTINGS = EVENT_SETTINGS.body;
    } catch (err) {
      throw new Error(err.toString());
    }
  };

  renderItems = () => {
    return (
      <Menu
        className={"header-menu"}
        anchorEl={this.state.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={this.state.open}
        onClose={this.handleClose}
      >
        {this.props.items.map((item, index) => {
          return (
            <div key={index}>
              <MenuItem
                className={
                  item.selected ? "header-menu-item-active" : "header-menu-item"
                }
                onClick={async () => await this.handleActiveItem(item)}
              >
                <div>
                  {index + 1}. {item.title}
                </div>
              </MenuItem>
            </div>
          );
        })}
      </Menu>
    );
  };

  renderHeaderItem = () => {
    if (this.props.items && this.props.items.length) {
      return (
        <Fragment>
          <Button
            size="medium"
            onClick={this.handleMenu}
            color="inherit"
            startIcon={this.props.icon}
            className={"header-item"}
          >
            {this.props.title}
          </Button>
          {this.renderItems()}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Button
            size="medium"
            onClick={this.props.onClick}
            color="inherit"
            startIcon={this.props.icon}
            className={"header-item"}
          >
            {this.props.title}
          </Button>
        </Fragment>
      );
    }
  };

  render() {
    return <Fragment>{this.renderHeaderItem()}</Fragment>;
  }
}

export default withRouter(HeaderItem);

import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import NxButton from "../controls/nx_button";
import PropTypes from "prop-types";
import "./nx_dialog.scss";

const Nx_Dialog = (props) => {
  const [open, setOpen] = useState(props.open ? props.open : false);

  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose();
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(() => props.open);
  }, [props.open]);

  const handleButtons = () => {
    if (props.buttons && Array.isArray(props.buttons) && props.buttons.length) {
      return props.buttons.map((button, index) => {
        const currentButton = button;
        return (
          <NxButton
            key={index}
            label={currentButton.label ? currentButton.label : ""}
            variant={
              currentButton.variant
                ? currentButton.variant
                : index === 0
                ? "outlined"
                : "contained"
            }
            onClick={
              currentButton.onClick
                ? async () => {
                    await currentButton.onClick();
                  }
                : () => {}
            }
          />
        );
      });
    } else return null;
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => handleClose()}
      className={"nx_dialog"}
      sx={props.sx}
      fullWidth={props.fullWidth}
      maxWidth="md"
    >
      <DialogTitle component="div">
        <h1 style={{ textTransform: "capitalize", fontSize: "26px" }}>
          {props.title ? props.title : ""}
        </h1>
        <h4
          style={{
            fontSize: "16px",
            textAlign: "left",
            width: "75%",
            color: "rgb(242, 175, 92)",
          }}
        >
          {props.helper ? props.helper : ""}
        </h4>
      </DialogTitle>
      <DialogContent sx={{ overflowY: "auto" }}>
        {props.children ? props.children : null}
      </DialogContent>
      <DialogActions>
        <div className="uf_form_buttons">{handleButtons()}</div>
      </DialogActions>
    </Dialog>
  );
};

Nx_Dialog.prototype = {
  open: PropTypes.bool,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  buttons: PropTypes.array,
  sx: PropTypes.object,
};

export default Nx_Dialog;

import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemDropDown from "./controls/ListItemDropDown";
import ListItem from "./controls/ListItem";
import withRouter from "../../../components/withRouter";
import { Fragment } from "react";
import Grid from "../../layouts/Grid/Grid";
import Nx_image from "../../controls/nx_image";

function Menu(props) {
  const handleActiveItem = (item) => {
    if (item.hasOwnProperty("url")) {
      if (item.url.includes("..")) {
        item.active =
          item.url.split("..")[1] === props.router.location.pathname;
      } else {
        item.active = item.url === props.router.location.pathname;
      }
    } else {
      if (item.hasOwnProperty("children")) {
        item.children.forEach((child) => {
          if (child.hasOwnProperty("url")) {
            child.active =
              child.url.split("..")[1] === props.router.location.pathname;
            item.active = child.active === true;
          }
        });
        item.active = item.children.findIndex((child) => child.active) > -1;
        item.open = item.active;
      } else {
        item.active = false;
      }
    }
  };

  const renderMenuItems = () => {
    return (
      <Box sx={{ width: 200 }} role="presentation">
        <List>
          {props.items && props.items.length
            ? props.items.map((item, index) => {
                handleActiveItem(item);
                if (item.read === true || item.write === true) {
                  return (
                    <Fragment key={index}>
                      {item.children && item.children.length ? (
                        <ListItemDropDown
                          children={item.children}
                          active={item.active}
                          open={item.open}
                          icon={item.icon}
                          title={item.title}
                        />
                      ) : (
                        <ListItem
                          title={item.title}
                          active={item.active}
                          icon={item.icon}
                          onClick={() => props.router.navigate(`${item.url}`)}
                        />
                      )}
                    </Fragment>
                  );
                }
                return null;
              })
            : null}
        </List>
      </Box>
    );
  };

  const findImage = () => {
    const url = window.location.href;
    if (url.includes("realty")) {
      return "realtyon.png";
    } else if (url.includes("financemagnates")) {
      return "financemagnates.png";
    } else if (url.includes("ultimatefintech")) {
      return "ifxexpo.png";
    } else {
      return "ifxexpo.png";
    }
  };

  return (
    <Grid
      style={{
        gridAutoFlow: "row",
        gridTemplateRows: "89px auto",
        overflow: "hidden",
      }}
    >
      <Grid
        style={{ justifyContent: "center", alignItems: "center" }}
        className={"border_header_left"}
      >
        <Nx_image
          source={`../../assets/images/${findImage()}`}
          alt={"logo"}
          style={{ width: "170px", height: "46px", objectFit: "contain" }}
          onClick={() => props.router.navigate(`events/${global.UF.event_id}`)}
        />
      </Grid>
      <Grid
        style={{
          justifyContent: "center",
          overflow: "hidden",
          overflowY: "auto",
          marginTop: "20px",
        }}
      >
        {renderMenuItems()}
      </Grid>
    </Grid>
  );
}

export default withRouter(Menu);

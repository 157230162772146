import React, { Fragment } from "react";
import UF_Button from "../../nix_components/ui_components/controls/nx_button.js";
import Dialog from "@mui/material/Dialog/Dialog";
import Typography from "@mui/material/Typography/Typography";
import UF_DraggableList from "../../controls/UF_DraggableList";

class ReorderModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      items: [],
    };
  }

  onClick = () => {
    this.state.items.forEach((item, index) => {
      if (Object.keys(item.GetChangedFields()).length) {
        item.Save();
      }
      if (this.state.items.length === index + 1) {
        this.props.onClose();
        // this.props.onClick()
      }
    });
  };

  orderArrayByOrdering = (a, b) => {
    if (a.ordering < b.ordering) {
      return -1;
    }
    if (a.ordering > b.ordering) {
      return 1;
    }
    return 0;
  };

  componentDidMount = () => {
    global.UF.dataProvider.get(
      this.props.table,
      { event_id: global.UF.event_id },
      (data) => {
        data.sort(this.orderArrayByOrdering);
        if (this.props.table === "sponsors") {
          if (data.length) {
            data.forEach((sponsor, index) => {
              sponsor.types_labels = [];
              let tempArray = JSON.parse(sponsor.types);
              tempArray.forEach((tempItem, tempIndex) => {
                global.UF.dataProvider.referenceProvider.get(
                  "sponsor_types",
                  (types) => {
                    if (types && types.length) {
                      types.forEach((type, typeIndex) => {
                        if (tempItem == type.id) {
                          sponsor.types_labels.push(type.label);
                        }
                        if (
                          index + 1 === data.length &&
                          tempIndex + 1 === tempArray.length &&
                          typeIndex + 1 === types.length
                        ) {
                          this.state.items = data;
                          this.state.loaded = true;
                          this.forceUpdate();
                        }
                      });
                    } else {
                      this.state.items = data;
                      this.state.loaded = true;
                      this.forceUpdate();
                    }
                  }
                );
              });
            });
          } else {
            this.setState({ items: data, loaded: true });
          }
        } else {
          this.setState({ items: data, loaded: true });
        }
      },
      true
    );
  };

  onDragEnd = ({ destination, source }) => {
    const currentItemIndex = source.index;
    const wantedItemIndex = destination.index;
    const currentItem = this.state.items[currentItemIndex];
    const wantedOrdering = this.state.items[wantedItemIndex].ordering;
    let tempArr = [...this.state.items];

    if (currentItemIndex < wantedItemIndex) {
      for (let i = this.state.items.length; i >= 0; i--) {
        if (i <= wantedItemIndex && currentItemIndex < i) {
          tempArr[i].ordering = this.state.items[i - 1].ordering;
        }
      }
    } else if (currentItemIndex > wantedItemIndex) {
      for (let j = 0; j < this.state.items.length; j++) {
        if (j >= wantedItemIndex && j < currentItemIndex) {
          tempArr[j].ordering = this.state.items[j + 1].ordering;
        }
      }
    }
    currentItem.ordering = wantedOrdering;
    this.state.items = tempArr.sort(this.orderArrayByOrdering);
    this.forceUpdate();
  };

  renderFields = () => {
    if (this.props.table === "sponsors") {
      return (
        <Fragment>
          <span style={{ width: "200px", textAlign: "center" }}>
            Company name
          </span>
          <span style={{ width: "200px", textAlign: "center" }}>
            Sponsorship Type(s)
          </span>
          <span style={{ width: "200px", textAlign: "center" }}>Booth(s)</span>
          <span style={{ width: "200px", textAlign: "center" }}>Ordering</span>
        </Fragment>
      );
    } else if (this.props.table === "agenda_topics") {
      return (
        <Fragment>
          <span style={{ width: "200px", textAlign: "center" }}>Title</span>
          <span style={{ width: "200px", textAlign: "center" }}>Ordering</span>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <span style={{ width: "200px", textAlign: "center" }}>Name</span>
          <span style={{ width: "200px", textAlign: "center" }}>Ordering</span>
        </Fragment>
      );
    }
  };

  capitalizeFirstLetter = (string) => {
    if (string.length) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  render() {
    return (
      <Fragment>
        <Dialog
          open={this.props.open}
          keepMounted
          onClose={this.props.onClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="nx_modal_wrapper">
            <div className="nx_modal_title">
              <Typography variant="h5">
                {this.capitalizeFirstLetter(this.props.table)} Ordering
              </Typography>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  position: "relative",
                  width: "650px",
                }}
              >
                {this.renderFields()}
              </div>
              <UF_DraggableList
                items={this.state.items}
                table={this.props.table}
                onDragEnd={this.onDragEnd}
              />
            </div>
            <div className="nx_buttons">
              <UF_Button
                onClick={this.props.onClose}
                variant={"outlined"}
                label={"Cancel"}
              />
              <UF_Button onClick={this.onClick} label={"Save"} />
            </div>
          </div>
        </Dialog>
      </Fragment>
    );
  }
}

export default ReorderModal;

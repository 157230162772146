import React from 'react';
import PropTypes from 'prop-types'
import Button from "@mui/material/Button/Button";
import "./controls.scss"


function Nx_Button(props) {

    return (
        <Button
            variant={props.variant}
            size={props.size}
            onClick={props.onClick}
            disabled={props.disabled}
            type={props.type}
            style={props.style}
            className={`${props.variant === 'contained' ? 'nx_button_contained' : 'nx_button_outlined'} ${props.className}`}
        >
            <span>{props.label}</span>
        </Button>
    );
}

Nx_Button.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    style : PropTypes.object,
}

Nx_Button.defaultProps = {
    className: '',
    variant: 'contained',
    size: 'medium',
}

export default Nx_Button;

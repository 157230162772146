import React from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import data_types from "../../../../nix_components/data/data_types";
import Nx_Loader from "../../../../nix_components/components/nx_loader";

class Awards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: "award",
      scopeCondition: false,
      loaded: false,
    };
  }

  buttons = {};

  formConfig = {
    rows: [
      [
        "name",
        {
          label: "Award selection limit",
          datatype: data_types.number,
          field: "nomination_limit",
        },
      ],
      [
        {
          label: "Status",
          field: "status",
          reference: "awardsStatuses",
          datatype: data_types.reference,
        },
      ],
    ],
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  componentDidMount() {
    this.findCurrentPermission();
  }

  render() {
    return (
      <UF_Content
        table="awards"
        title="Awards"
        permission="award"
        buttons={this.buttons}
        afterDelete={this.props.fetchMenuItems}
        afterSave={this.props.fetchMenuItems}
        edit={this.state.scopeCondition}
        export={this.state.scopeCondition}
        delete={this.state.scopeCondition}
        add={this.state.scopeCondition}
        sync={this.state.scopeCondition}
        formConfig={this.formConfig}
      />
    );
  }
}

export default Awards;

import React, {Fragment} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import LoginScreen from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";


class Public extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    renderElement = () => {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/forgot-password" element={<ForgotPassword/>}/>
                    <Route path="/reset-password" element={<ResetPassword/>}/>
                    <Route path="/" element={<LoginScreen onLogin={this.props.onLogin}/>}/>
                    <Route path="/*" element={<Navigate to='/'/>}/>
                </Routes>
            </BrowserRouter>
        )
    }

    render() {
        return (
            <Fragment>
                {this.renderElement()}
            </Fragment>
        )
    }
}


export default Public;

import React, {Component} from 'react'
import {Dialog, Typography} from '@mui/material'
import PropTypes from 'prop-types';
import UF_Button from "../../nix_components/ui_components/controls/nx_button.js";
import './set_password_modal.scss'

class ConfirmModalEmail extends React.Component {

    constructor(props) {
        super(props);
    }

    sendEmail = () => {
        this.props.onSendEmail(() => {
            this.props.onClose();
        });
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                keepMounted
                onClose={this.props.onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className="nx_modal_wrapper">
                    <div className="nx_modal_title">
                        <Typography variant='h5'>{this.props.text} {this.props.email}?</Typography>
                    </div>
                    <div className="nx_buttons">
                        <UF_Button onClick={this.sendEmail} label={'Ok'}/>
                    </div>
                </div>
            </Dialog>
        )
    }
}

ConfirmModalEmail.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
};

export default ConfirmModalEmail
import React, { Fragment } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import data_types from "../../../../nix_components/data/data_types";
import { validators } from "../../../../nix_components/data/data_validators";
import withRouter from "../../../../nix_components/components/withRouter";
import { IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import uuid from "react-uuid";
import ConfirmModalEmail from "../../../../components/modals/ConfirmModalEmail";
import { Tooltip } from "@mui/material";

class MediaPartnersRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      package_id: null,
      emailItem: null,
      permission: "media-partner",
      scopeCondition: false,
      loaded: false,
    };
    this.additional_data = ["notes"];
  }

  buttons = {
    registrationStatus: (params) => {
      return params.row.status === "Under review" &&
        this.state.scopeCondition ? (
        <Fragment>
          <IconButton
            aria-label="delete"
            color="default"
            onClick={() => this.handleRegistrationStatus(params.row, true)}
          >
            <CheckCircleIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="default"
            onClick={() => this.handleRegistrationStatus(params.row, false)}
          >
            <UnpublishedIcon />
          </IconButton>
        </Fragment>
      ) : null;
    },
  };

  formConfig = {
    rows: [
      [
        {
          field: "media_partner_id",
          datatype: data_types.reference,
          reference: "media_partners",
          label: "Media Partner",
          onChange: (dataItem) => {
            const media_partner_id = dataItem.media_partner_id;
            global.UF.dataProvider.get(
              `media_partners`,
              { id: media_partner_id },
              (media_partners) => {
                if (media_partners.length) {
                  const current_media_partner = media_partners[0];
                  dataItem.email = current_media_partner.email;
                  dataItem.company = current_media_partner.name;
                  dataItem.website = current_media_partner.website;
                  this.forceUpdate();
                }
              }
            );
          },
        },
        {
          field: "first_name",
          label: "First name",
        },
        {
          field: "last_name",
          label: "Last name",
        },
      ],
      [
        {
          field: "email",
          label: "Email",
        },
        {
          label: "Tickets",
          data: Array.from(
            {
              length: global.UF.EVENT_SETTINGS
                ? global.UF.EVENT_SETTINGS.EXHIBITOR_TICKET_COUNT
                : 0,
            },
            (_, i) => i + 1
          ),
          datatype: data_types.select,
          field: "ticket_quantity",
        },
        {
          field: "phone",
          label: "Phone",
          defaultValue: " ",
          validators: [],
        },
      ],
      [
        {
          field: "company",
          label: "Company",
        },
        {
          field: "website",
          label: "Website",
        },
      ],
      [
        {
          label: "Notes",
          datatype: data_types.string,
          multiline: true,
          field: "notes",
          name: "notes",
          validators: [validators.isNull],
        },
      ],
    ],
  };

  gridProps = {
    fields: [
      "first_name",
      "website",
      "package_id",
      "status",
      "ticket_quantity",
      "company",
      "category",
      "subcategory",
      "evidence",
      "notes",
      "created_at",
    ],
  };

  componentDidMount = () => {
    this.handleCustomGridManipulation();
    global.UF.dataProvider.datastructure["registrations"].filters = {
      type: "media-partner",
    };
    global.UF.dataProvider.datastructure["registrations"].fieldsArr
      .find((field) => field.name === "media_partner_id")
      .validators.push(validators.isNull);
    global.UF.dataProvider.datastructure[
      "registrations"
    ].ignoreValidation?.push("speaker_id", "sponsor_id", "phone");
    global.UF.dataProvider.get(`packages`, { name: "VIP" }, (packages) => {
      if (packages.length) {
        this.state.package_id = packages[0].id;
      } else {
        global.UF.setAlertVisibility(
          true,
          "There is no package with name VIP",
          "warning"
        );
        global.UF.dataProvider.datastructure[
          "registrations"
        ].ignoreValidation?.push("package_id");
      }
      this.findCurrentPermission();
    });
  };

  handleRegistrationStatus = (item, bool) => {
    item.status = bool === true ? "Completed" : "Rejected";
    global.UF.dataProvider.get(
      `attendees`,
      { registration_id: item.id, status: "pending" },
      (attendees) => {
        item.Save(() => {
          if (attendees.length) {
            attendees.forEach((attendee) => {
              attendee.status = bool === true ? "approved" : "rejected";
              attendee.Save();
            });
          }
        });
      }
    );
  };

  handleResendEmailVIP = (callback) => {
    const body = {
      id: this.state.emailItem.id,
      event_id: global.UF.event_id,
    };
    global.UF.makeRequest(
      `POST`,
      `/api/media-partner-registration`,
      body,
      true,
      (success) => {
        callback(success);
      },
      (error) => {
        console.error(
          "Error occurred when tried to insert Media Partner Registration",
          error
        );
        callback(error);
      }
    );
  };

  handleEmailValidation = async (dataItem) => {
    if (!dataItem) {
      throw new Error("Not valid registration provided!");
    }

    const email = dataItem.email.toLowerCase().trim();

    if (dataItem.isNew) {
      const registration = await global.UF.dataProvider.get_v2(
        `registrations`,
        {
          email: email,
          event_id: global.UF.event_id,
          type: "media-partner",
        }
      );

      if (registration.length) {
        throw new Error(
          `Registration email needs to be unique, a registration with email : ${email} already exists!`
        );
      }

      return true;
    }

    return true;
  };

  onSave = async (item, callback) => {
    try {
      await this.handleEmailValidation(item);
      if (item.isNew) {
        if (this.state.package_id) {
          item.package_id = this.state.package_id;
        }
        item.id = uuid();
        item.Save(() => {
          const body = {
            id: item.id,
            event_id: global.UF.event_id,
          };

          global.UF.makeRequest(
            `POST`,
            `/api/media-partner-registration`,
            body,
            true,
            (success) => {
              if (callback) {
                callback(success);
              }
            },
            (error) => {
              console.error(
                "Error occurred when tried to insert Media Partner Registration",
                error
              );
              if (callback) {
                callback(error);
              }
            }
          );
        });
      } else {
        item.Save(() => {
          if (callback) {
            callback();
          }
        });
      }
    } catch (err) {
      global.UF.setAlertVisibility(true, err.toString(), "error");
      throw new Error(err);
    }
  };

  onDataItemsLoad = (data, callback) => {
    if (data && data.length) {
      data.forEach((registration, index) => {
        global.UF.dataProvider.get(
          `attendees`,
          {
            registration_id: registration.id,
            status: "approved",
          },
          (attendees) => {
            registration.attendees_completed = attendees.length;
            if (index + 1 === data.length) {
              if (callback) {
                callback();
              }
            }
          }
        );
      });
    } else {
      callback();
    }
  };

  renderResendVIPLinkModal = () => {
    return (
      <ConfirmModalEmail
        open={this.state.emailItem !== null}
        onClose={() => this.setState({ emailItem: null })}
        email={this.state.emailItem ? this.state.emailItem.email : ""}
        onSendEmail={this.handleResendEmailVIP}
        text={"Are you sure you want to resend confirmation email to"}
      />
    );
  };

  handleCustomGridManipulation = () => {
    const ds = global.UF.data_structure["registrations"];
    const fields = ds.fieldsArr;
    fields.find((field) => field.Field === "status").onClick = (dataItem) => {
      this.setState({ emailItem: dataItem });
    };
    fields.find((field) => field.Field === "ticket_quantity").renderCell = (
      dataItem
    ) => {
      return (
        <Tooltip
          title={`${dataItem.value ? dataItem.value : 0} (${
            dataItem.row.attendees_completed
          })`}
        >
          <a
            href={this.navigateToAttendees(dataItem.row)}
            target="_blank"
            rel="noreferrer"
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            {dataItem.value ? dataItem.value : 0} (
            {dataItem.row.attendees_completed})
          </a>
        </Tooltip>
      );
    };
  };

  navigateToAttendees = (dataItem) => {
    const registration = dataItem;
    switch (registration.type) {
      case "regular":
        return `${window.location.origin}/events/${global.UF.event_id}/registrations-attendees?registration_id=${registration.id}`;
      case "sponsor":
        return `${window.location.origin}/events/${global.UF.event_id}/sponsor-attendees?registration_id=${registration.id}`;
      case "speaker":
      case "speaker-guest":
        return `${window.location.origin}/events/${global.UF.event_id}/speaker-attendees?registration_id=${registration.id}`;
      case "media-partner":
        return `${window.location.origin}/events/${global.UF.event_id}/media-partner-attendees?registration_id=${registration.id}`;
    }
  };

  handleRowDoubleClick = (params) => {
    navigator.clipboard
      .writeText(
        `${global.UF.EVENT_SETTINGS.EMAILS_VIP_REGISTRATION_REDIRECT_LINK}/?id=${params.id}`
      )
      .then(() => {
        global.UF.setAlertVisibility(
          true,
          "VIP Registration link copied to clipboard",
          "info"
        );
      });
  };

  handleBeforeAddNew = (dataItem, callback) => {
    dataItem.type = "media-partner";
    dataItem.event_id = global.UF.event_id;
    if (callback) {
      callback();
    }
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  render() {
    return (
      <Fragment>
        {this.state.emailItem && this.renderResendVIPLinkModal()}
        <UF_Content
          table="registrations"
          id={this.props.event_id}
          onDataItemsLoad={this.onDataItemsLoad}
          registrationType={"media-partner"}
          title="Media Partners Registration"
          permission={"media-partner"}
          formConfig={this.formConfig}
          gridProps={this.gridProps}
          buttons={this.buttons}
          edit={this.state.scopeCondition}
          add={this.state.scopeCondition}
          sync={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          export={this.state.scopeCondition}
          additional_data={this.additional_data}
          onSave={this.onSave}
          rowDoubleClick={this.handleRowDoubleClick}
          beforeAddNew={this.handleBeforeAddNew}
        />
      </Fragment>
    );
  }
}

export default withRouter(MediaPartnersRegistration);

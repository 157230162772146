import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import './controls.scss'


function UF_DATEPICKER(props) {

    const onChange = (event) => {
        if(event && event.$d) {
            let date = new Date(event.$d)
            
            props.onChange(date)
        }
        else {
            // props.onChange(null);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={props.fieldConfig ? props.fieldConfig.label : props.label}
                inputFormat={"YYYY-MM-DD"}
                disabled={props.disabled}
                value={props.value !== null ? new Date(props.value) : null}
                onChange={onChange}
                renderInput={(params) => <TextField size={'small'} {...params} error={props.error}
                                                    helperText={props.helperText} sx={{width : "100%"}}/>}
                className={'nx_textfield'}
            />
        </LocalizationProvider>
    )
}

UF_DATEPICKER.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['outlined', 'filled', 'standard',]),
    fullWidth: PropTypes.bool,
    autoFocus: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    fontSize: PropTypes.string,
    fontLabelSize: PropTypes.string,
    multiline: PropTypes.bool,
    size: PropTypes.oneOf(['medium', 'small', 'string',]),
}

UF_DATEPICKER.defaultProps = {
    label: '',
    className: '',
    disabled: false,
    fullWidth: true,
    autoFocus: false,
    error: false,
    helperText: '',
    type: 'text',
    fontSize: '12px',
    fontLabelSize: '11px',
    multiline: false,
    size: 'small',
}

export default UF_DATEPICKER

import React, { Fragment } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import data_types from "../../../../nix_components/data/data_types";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import SettingsIcon from "@mui/icons-material/Settings";
import moment from "moment";
import SetEmailTemplateModal from "../../../../components/modals/SetEmailTemplateModal";
import uuid from "react-uuid";

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: "event",
      scopeCondition: false,
      templateItem: null,
      loaded: false,
    };
  }

  buttons = {
    // setEmailTemplate: (params) => {
    //     return (
    //         this.state.scopeCondition ?
    //         <IconButton onClick={() => this.setState({templateItem: params.row})}>
    //             <EmailIcon/>
    //         </IconButton> : null
    //     )
    // }
  };

  formConfig = {
    rows: [
      [
        "name",
        {
          field: "website",
          label: "Website",
          datatype: data_types.string,
        },
      ],
      [
        {
          label: "Start date",
          datatype: data_types.date,
          field: "start_date",
          onChange: (dataItem) => {
            let date = new Date(dataItem[`start_date`]);
            const nextDayDate = new Date(date.getTime() + 86400000);
            if (
              new Date(dataItem[`end_date`]).getTime() <=
              new Date(new Date(dataItem[`start_date`]).getTime())
            ) {
              dataItem[`end_date`] = moment(nextDayDate).format("YYYY-MM-DD");
            }
            if (
              new Date(
                dataItem[`regular_registrations_close_date`]
              ).getTime() <=
              new Date(new Date(dataItem[`start_date`]).getTime())
            ) {
              dataItem[`regular_registrations_close_date`] = nextDayDate;
            }
            if (
              new Date(dataItem[`vip_registrations_close_date`]).getTime() <=
              new Date(new Date(dataItem[`start_date`]).getTime())
            ) {
              dataItem[`vip_registrations_close_date`] = nextDayDate;
            }
            this.forceUpdate();
          },
        },
        {
          label: "End date",
          datatype: data_types.date,
          field: "end_date",
        },
      ],
      [
        {
          label: "Vip Registrations close date",
          datatype: data_types.datetime,
          field: "vip_registrations_close_date",
        },
        {
          label: "Regular Registrations close date",
          datatype: data_types.datetime,
          field: "regular_registrations_close_date",
        },
      ],
    ],
  };

  renderSetEmailTemplateModal = () => {
    return (
      <SetEmailTemplateModal
        open={this.state.templateItem !== null}
        onClose={() => this.setState({ templateItem: null })}
        event_id={this.state.templateItem ? this.state.templateItem.id : null}
      />
    );
  };

  onSetEventSettingsClick = (event) => {
    const eventSettingsItem =
      global.UF.dataProvider.datastructure[`event_settings`].new();
    eventSettingsItem.event_id = event.id;
    eventSettingsItem.event_name = event.name;
    this.setState({ settingsItem: eventSettingsItem });
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  componentDidMount() {
    this.findCurrentPermission();
  }

  onSave = (dataItem, callback) => {
    if (dataItem) {
      if (dataItem.isNew) {
        try {
          const event_id = uuid();
          dataItem.id = event_id;
          dataItem.Save(async () => {
            await global.UF.makeRequest_v2(
              `POST`,
              `/api/settings/event`,
              { event_id: event_id },
              true
            );
            this.props.fetchMenuItems();
            if (callback) {
              callback();
            }
          });
        } catch (err) {
          console.error(`Error occurred while trying to save new event ${err}`);
          throw new Error(err);
        }
      } else {
        dataItem.Save(() => {
          this.props.fetchMenuItems();
          if (callback) {
            callback();
          }
        });
      }
    } else {
      console.error(
        `Error occurred while trying to save event, missing dataItem`
      );
    }
  };

  render() {
    return (
      <Fragment>
        {this.state.settingsItem && this.renderSetSettingsModal()}
        <UF_Content
          table="events"
          title="Events"
          permission="event"
          buttons={this.buttons}
          afterDelete={this.props.fetchMenuItems}
          onSave={this.onSave}
          edit={this.state.scopeCondition}
          export={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          add={this.state.scopeCondition}
          sync={this.state.scopeCondition}
          formConfig={this.formConfig}
        />
      </Fragment>
    );
  }
}

export default Events;

import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import withRouter from "../../../nix_components/components/withRouter";
import RegistrationPackages from "./RegistrationPackages/RegistrationPackages";
import Attendees from "./Attendees/Attendees";
import SponsorsTypes from "./SponsorsTypes/SponsorsTypes";
import SponsorsCategories from "./SponsorsCategories/SponsorsCategories";
import SponsorsExhibitors from "./SponsorsExhibitors/SponsorsExhibitors";
// start ifx expo 2024 cyprus refactor this to be dynamic for every event, needs changes to DB
import Statistics from "./Statistics/Statistics";
import WelcomeParty from "./Statistics/WelcomeParty";
import OnSpotOne from "./Statistics/OnSpotOne";
import OnSpotTwo from "./Statistics/OnSpotTwo";
// end stats ifx expo 2024 cyprus
import Speakers from "./Speakers/Speakers";
import MediaPartners from "./MediaPartners/MediaPartners";
import Lunch from "./Lunch/Lunch";
import AgendaTopics from "./AgendaTopics/AgendaTopics";
import SpeakerRegistration from "./Registration/SpeakerRegistration";
import SponsorRegistration from "./Registration/SponsorRegistration";
import RegularRegistration from "./Registration/RegularRegistration";
import PackageCategories from "./PackageCategories/PackageCategories";
import PackageSubcategories from "./PackageSubcategories/PackageSubcategories";
import ScheduleTypes from "./ScheduleTypes/ScheduleTypes";
import ExhibitorExport from "./Exports/ExhibitorExport";
import PromoCodes from "./PromoCodes/PromoCodes";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import PeopleIcon from "@mui/icons-material/People";
import PaidIcon from "@mui/icons-material/Paid";
import CategoryIcon from "@mui/icons-material/Category";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import InventoryIcon from "@mui/icons-material/Inventory";
import HandshakeIcon from "@mui/icons-material/Handshake";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import CelebrationIcon from "@mui/icons-material/Celebration";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import DiscountIcon from "@mui/icons-material/Discount";
import MediaPartnersRegistration from "./Registration/MediaPartnersRegistration";

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event_id: global.UF.event_id,
    };
  }

  generateMenuItems = () => {
    this.prefix = "../events/" + this.state.event_id;
    return (this.menuItems = [
      {
        title: "Registration",
        level: 1,
        scope: "registration",
        open: false,
        icon: <AppRegistrationIcon />,
        children: [
          {
            title: "Registration",
            element: () => {
              return <RegularRegistration event_id={this.state.event_id} />;
            },
            icon: <AppRegistrationIcon />,
            level: 2,
            url: this.prefix + `/registrations-registration`,
          },
          {
            title: "Attendees",
            level: 2,
            url: this.prefix + `/registrations-attendees`,
            icon: <PeopleIcon />,
            element: () => {
              return <Attendees event_id={this.state.event_id} />;
            },
          },
        ],
      },
      {
        title: "Sponsors",
        scope: "sponsor",
        level: 1,
        icon: <PaidIcon />,
        open: false,
        children: [
          {
            title: "Types",
            level: 2,
            url: this.prefix + `/sponsors-types`,
            icon: <FormatListNumberedIcon />,
            element: () => {
              return <SponsorsTypes event_id={this.state.event_id} />;
            },
          },
          {
            title: "Categories",
            level: 2,
            url: this.prefix + `/sponsors-categories`,
            icon: <CategoryIcon />,
            element: () => {
              return <SponsorsCategories event_id={this.state.event_id} />;
            },
          },
          {
            title: "Sponsors / Exhibitors",
            level: 2,
            url: this.prefix + `/sponsors-exhibitors`,
            icon: <PaidIcon />,
            element: () => {
              return <SponsorsExhibitors event_id={this.state.event_id} />;
            },
          },
          {
            title: "Registration",
            level: 2,
            icon: <AppRegistrationIcon />,
            url: this.prefix + `/sponsors-registration`,
            element: () => {
              return <SponsorRegistration event_id={this.state.event_id} />;
            },
          },
          {
            title: "Attendees",
            level: 2,
            icon: <PeopleIcon />,
            url: this.prefix + `/sponsor-attendees`,
            element: () => {
              return <Attendees event_id={this.state.event_id} />;
            },
          },
        ],
      },
      {
        title: "Speakers",
        level: 1,
        scope: "speaker",
        open: false,
        icon: <KeyboardVoiceIcon />,
        children: [
          {
            title: "Speakers",
            level: 2,
            icon: <KeyboardVoiceIcon />,
            url: this.prefix + `/speakers`,
            element: () => {
              return <Speakers event_id={this.state.event_id} />;
            },
          },
          {
            title: "Registration",
            level: 2,
            url: this.prefix + `/speakers-registration`,
            icon: <AppRegistrationIcon />,
            element: () => {
              return <SpeakerRegistration event_id={this.state.event_id} />;
            },
          },
          {
            title: "Attendees",
            level: 2,
            url: this.prefix + `/speaker-attendees`,
            icon: <PeopleIcon />,
            element: () => {
              return <Attendees event_id={this.state.event_id} />;
            },
          },
        ],
      },
      {
        title: "Media Partners",
        level: 1,
        icon: <HandshakeIcon />,
        scope: "media-partner",
        children: [
          {
            title: "Media Partners",
            level: 2,
            icon: <HandshakeIcon />,
            url: this.prefix + `/media-partners`,
            element: () => {
              return <MediaPartners event_id={this.state.event_id} />;
            },
          },
          {
            title: "Registration",
            level: 2,
            icon: <AppRegistrationIcon />,
            url: this.prefix + `/media-partner-registration`,
            element: () => {
              return (
                <MediaPartnersRegistration event_id={this.state.event_id} />
              );
            },
          },
          {
            title: "Attendees",
            level: 2,
            icon: <PeopleIcon />,
            url: this.prefix + `/media-partner-attendees`,
            element: () => {
              return <Attendees event_id={this.state.event_id} />;
            },
          },
        ],
      },
      {
        title: "Packages",
        scope: "package",
        open: false,
        icon: <InventoryIcon />,
        level: 1,
        children: [
          {
            title: "Packages",
            level: 2,
            icon: <InventoryIcon />,
            element: () => {
              return <RegistrationPackages event_id={this.state.event_id} />;
            },
            url: this.prefix + `/registrations-packages`,
          },
          {
            title: "Package Categories",
            level: 2,
            icon: <CategoryIcon />,
            element: () => {
              return <PackageCategories event_id={this.state.event_id} />;
            },
            url: this.prefix + `/package-categories`,
          },
          {
            title: "Package Subcategories",
            level: 2,
            icon: <CategoryIcon />,
            element: () => {
              return <PackageSubcategories event_id={this.state.event_id} />;
            },
            url: this.prefix + `/package-subcategories`,
          },
        ],
      },

      {
        title: "Agenda Topics",
        scope: "agenda",
        level: 1,
        icon: <ViewAgendaIcon />,
        open: false,
        children: [
          {
            title: "Agenda Topics",
            scope: "agenda",
            icon: <ViewAgendaIcon />,
            level: 1,
            url: this.prefix + `/agenda-topics`,
            element: () => {
              return <AgendaTopics event_id={this.state.event_id} />;
            },
          },
          {
            title: "Schedule Types",
            scope: "agenda",
            icon: <FormatListNumberedIcon />,
            level: 1,
            url: this.prefix + `/schedule-types`,
            element: () => {
              return <ScheduleTypes event_id={this.state.event_id} />;
            },
          },
        ],
      },
      {
        title: "Exports",
        scope: "export",
        level: 1,
        open: false,
        icon: <FileDownloadIcon />,
        children: [
          {
            title: "Sponsors/Exhibitors Export",
            scope: "export",
            icon: <FileDownloadIcon />,
            level: 1,
            url: this.prefix + `/sponsors-export`,
            element: () => {
              return <ExhibitorExport event_id={this.state.event_id} />;
            },
          },
        ],
      },
      {
        title: "Promo Codes",
        scope: "package",
        level: 1,
        icon: <DiscountIcon />,
        url: this.prefix + `/promo-codes`,
        element: () => {
          return <PromoCodes event_id={this.state.event_id} />;
        },
      },
      // {
      //     title: 'Registration Categories',
      //     scope: 'package',
      //     element: () => {
      //         return <RegistrationCategories event_id={this.state.event_id}/>
      //     },
      //     level: 1,
      //     url: this.prefix + `/registrations-categories`
      // },

      {
        title: "Lunch",
        scope: "lunch",
        level: 1,
        icon: <RestaurantIcon />,
        url: this.prefix + `/lunch`,
        element: () => {
          return <Lunch event_id={this.state.event_id} />;
        },
      },
      global.UF.EVENT_SETTINGS?.EVENT === "IFX"
        ? {
            title: "Statistics",
            scope: "statistics",
            level: 1,
            icon: <QueryStatsIcon />,
            children: [
              {
                title: "General Statistics",
                level: 2,
                icon: <AnalyticsIcon />,

                url: this.prefix + `/statistics`,
                element: () => {
                  return <Statistics event_id={this.state.event_id} />;
                },
              },
              {
                title: "Welcome Party",
                level: 2,
                icon: <CelebrationIcon />,
                element: () => {
                  return <WelcomeParty event_id={this.state.event_id} />;
                },
                url: this.prefix + `/statistics-welcome-party`,
              },
              {
                title: "OnSpot day 1",
                level: 2,
                icon: <Filter1Icon />,
                element: () => {
                  return <OnSpotOne event_id={this.state.event_id} />;
                },
                url: this.prefix + `/statistics-onspot-1`,
              },
              {
                title: "OnSpot day 2",
                level: 2,
                icon: <Filter2Icon />,
                element: () => {
                  return <OnSpotTwo event_id={this.state.event_id} />;
                },
                url: this.prefix + `/statistics-onspot-2`,
              },
            ],
          }
        : {},
    ]);
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.router.params.event_id != nextProps.router.params.event_id) {
      this.state.event_id = nextProps.router.params.event_id;
      this.props.getMenuItems(this.generateMenuItems());
    }
    return true;
  }

  componentDidMount() {
    if (!window.location.href.includes("/events")) {
      this.props.router.navigate(`/events/${this.state.event_id}`);
    }
    this.props.getMenuItems(this.generateMenuItems());
  }

  renderRoutes = () => {
    if (this.props.menuItems && this.props.menuItems.length) {
      return this.props.menuItems.map((menuItem, index) => {
        if (
          menuItem.hasOwnProperty("write") ||
          menuItem.hasOwnProperty("read")
        ) {
          if (menuItem.write || menuItem.read) {
            if (menuItem.hasOwnProperty("children")) {
              return menuItem.children.map((child, key) => {
                let currentMenuItem = menuItem.children.find(
                  (item) => item.url === child.url
                );
                return (
                  <Route
                    path={`/${currentMenuItem.url.split("/")[3]}`}
                    key={key}
                    element={currentMenuItem.element()}
                  />
                );
              });
            } else {
              let currentMenuItem = this.props.menuItems.find(
                (item) => item.url === menuItem.url
              );
              return (
                <Route
                  path={`/${currentMenuItem.url.split("/")[3]}`}
                  key={index}
                  element={currentMenuItem.element()}
                />
              );
            }
          } else return null;
        } else return null;
      });
    } else return null;
  };

  findCurrentRoute = () => {
    if (this.props.menuItems && Array.isArray(this.props.menuItems)) {
      const currentIndex = this.props.menuItems.findIndex((mI) => {
        return mI.hasOwnProperty("write") || mI.hasOwnProperty("read");
      });
      const currentItem =
        currentIndex > -1 ? this.props.menuItems[currentIndex] : null;
      if (currentItem != null) {
        if (
          currentItem.hasOwnProperty("children") &&
          currentItem.children.length > 0
        ) {
          const currentChild = currentItem.children[0];
          return (
            <Route
              path={`/*`}
              element={currentChild.element()}
              event_id={this.state.event_id}
            />
          );
        } else {
          return (
            <Route
              path={`/*`}
              element={currentItem.element()}
              event_id={this.state.event_id}
            />
          );
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  render() {
    this.routes = this.renderRoutes();

    return (
      <Fragment>
        <Routes>
          {this.routes}
          {this.findCurrentRoute()}
        </Routes>
      </Fragment>
    );
  }
}

export default withRouter(Events);

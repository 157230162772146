import * as React from 'react';
import UF_DraggableListItem from './UF_DraggableListItem';
import {
    DragDropContext,
    Droppable,
    OnDragEndResponder
} from 'react-beautiful-dnd';
import {Fragment} from "react"

const UF_DraggableList = React.memo(({ items, onDragEnd, table }) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
                {
                    provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {
                            items.map((item, index) => (
                            <Fragment key={index}>
                                <UF_DraggableListItem item={item} table={table} index={index} key={item.id} />
                            </Fragment>
                        ))}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
});

export default UF_DraggableList;

import React, { Fragment } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import data_types from "../../../../nix_components/data/data_types";
import { validators } from "../../../../nix_components/data/data_validators";
import ReorderModal from "../../../../components/modals/ReorderModal";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { IconButton } from "@mui/material";
import ReorderIcon from "@mui/icons-material/Reorder";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import TransferDataModal from "../../../../components/modals/TransferDataModal";

class MediaPartners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reorderOpen: false,
      transferOpen: false,
      permission: "media-partner",
      scopeCondition: false,
      loaded: false,
    };
  }

  buttons = {};

  tools = {
    reorder: () => {
      return this.state.scopeCondition ? (
        <Tooltip title={"Reorder"}>
          <IconButton onClick={() => this.setState({ reorderOpen: true })}>
            <ReorderIcon />
          </IconButton>
        </Tooltip>
      ) : null;
    },
    transfer: () => {
      return this.state.scopeCondition ? (
        <Tooltip title={"Transfer"}>
          <IconButton onClick={() => this.setState({ transferOpen: true })}>
            <MoveUpIcon />
          </IconButton>
        </Tooltip>
      ) : null;
    },
  };

  formConfig = {
    rows: [
      [
        "name",
        {
          field: "email",
          label: "Email",
          validators: [],
        },
      ],
      [
        {
          label: "Logo",
          field: "logo",
          datatype: data_types.file,
          requirements: {
            width: global.UF.EVENT_SETTINGS
              ? global.UF.EVENT_SETTINGS.MEDIA_PARTNER_IMAGE_WIDTH
              : "300",
            height: global.UF.EVENT_SETTINGS
              ? global.UF.EVENT_SETTINGS.MEDIA_PARTNER_IMAGE_HEIGHT
              : "100",
          },
        },
        {
          field: "website",
          label: "Website",
          validators: [validators.isWebsite],
        },
      ],
    ],
  };

  gridProps = {
    fields: ["name", "email", "website", "ordering", "created_at"],
  };

  fetchData = () => {};

  getFetchData = (fetchData) => {
    this.fetchData = fetchData;
  };

  renderReorderingModal = () => {
    return (
      <ReorderModal
        open={this.state.reorderOpen}
        onClose={() =>
          this.setState({ reorderOpen: false }, () => this.fetchData())
        }
        table={"media_partners"}
        onClick={this.fetchData}
      />
    );
  };

  renderTransferDataModal = () => {
    return (
      <TransferDataModal
        open={this.state.transferOpen}
        onClose={() =>
          this.setState({ transferOpen: false }, () => this.fetchData())
        }
        table={"media_partners"}
      />
    );
  };

  handleBeforeAddNew = (dataItem, callback) => {
    dataItem.event_id = global.UF.event_id;
    global.UF.makeRequest(
      `POST`,
      `/api/get-ordering`,
      { table: "media_partners" },
      true,
      (data) => {
        dataItem.ordering = data.body.ordering;
        if (callback) {
          callback();
        }
      },
      (error) => {
        console.error(error);
      },
      true,
      false
    );
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  componentDidMount() {
    this.findCurrentPermission();
  }

  render() {
    return (
      <Fragment>
        {this.state.reorderOpen && this.renderReorderingModal()}
        {this.state.transferOpen && this.renderTransferDataModal()}
        <UF_Content
          table="media_partners"
          id={this.props.event_id}
          permission={"media-partner"}
          buttons={this.buttons}
          edit={this.state.scopeCondition}
          export={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          add={this.state.scopeCondition}
          sync={this.state.scopeCondition}
          tools={this.tools}
          fetchData={this.getFetchData}
          title="Media Partners"
          formConfig={this.formConfig}
          gridProps={this.gridProps}
          beforeAddNew={this.handleBeforeAddNew}
        />
      </Fragment>
    );
  }
}

export default MediaPartners;

import React, { Fragment } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";

class ScheduleTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: "agenda",

      scopeCondition: false,
      loaded: false,
    };
  }

  buttons = {};

  formConfig = {
    rows: [["name"]],
  };

  gridProps = {
    fields: ["name", "created_at"],
  };

  handleBeforeAddNew = (dataItem, callback) => {
    dataItem.event_id = global.UF.event_id;
    if (callback) {
      callback();
    }
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  componentDidMount() {
    this.findCurrentPermission();
  }

  render() {
    return (
      <Fragment>
        <UF_Content
          table="schedule_types"
          id={this.props.event_id}
          permission={"agenda"}
          buttons={this.buttons}
          tools={this.tools}
          edit={this.state.scopeCondition}
          export={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          title="Schedule Types"
          formConfig={this.formConfig}
          gridProps={this.gridProps}
          add={this.state.scopeCondition}
          sync={this.state.scopeCondition}
          beforeAddNew={this.handleBeforeAddNew}
        />
      </Fragment>
    );
  }
}

export default ScheduleTypes;

import React, { Component, Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import data_types from "../data/data_types";
import "./nx_grid.scss";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import NotesIcon from "@mui/icons-material/Notes";
import withRouter from "./withRouter";
import Nx_Grid_Popover from "./nx_grid_popover";
import Grid from "../ui_components/layouts/Grid/Grid";
import moment from "moment";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

class Nx_Grid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      columns: [],
      page: 0,
      pageSize: 10,
      totalItems: 0,
      isLoading: true,
      sortField: [],
      popoverActive: false,
      activeRows: [],
      rowHeight: 52,
    };

    this.config = props.config
      ? props.config
      : props.fields
      ? props.fields.map(
          (field) => global.UF.data_structure[props.table].fields[field]
        )
      : global.UF.data_structure[props.table].fieldsArr;

    this.lastUpdate = props.lastUpdate;

    this.references = {
      count: 0,
      loaded: 0,
      arr: [],
    };
  }

  capitalizeFirstLetter = (string) => {
    if (string.length) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  generateHeaderName = (item) => {
    if (item.label.includes("_")) {
      return `${this.capitalizeFirstLetter(
        item.label.split("_")[0]
      )} ${this.capitalizeFirstLetter(item.label.split("_")[1])}`;
    } else {
      return this.capitalizeFirstLetter(item.label);
    }
  };

  componentDidMount = () => {
    this.state.sortField = Array.isArray(
      global.UF.data_structure[this.props.table].sorting
    )
      ? global.UF.data_structure[this.props.table].sorting
      : global.UF.data_structure[this.props.table].fieldsArr.findIndex(
          (field) => field.Field === "created_at"
        ) > -1
      ? [
          {
            field: "created_at",
            sort: "desc",
          },
        ]
      : [];
    this.props.syncData(this.fetchData);
    this.fetchReferences(true);
  };

  fetchReferences = (force = false) => {
    if (this.references.count > 0) {
      this.references.arr.forEach((ref) => {
        global.UF.dataProvider.referenceProvider.get(
          ref,
          (data) => {
            this.references[ref] = {};
            data.forEach(
              (item) => (this.references[ref][item.id] = item.label)
            );
            this.references.loaded++;
            if (this.references.loaded == this.references.count) {
              this.fetchData();
            }
          },
          force
        );
      });
    } else {
      this.fetchData();
    }
  };

  handleSortModelChange = (sortField) => {
    this.setState({ sortField }, this.fetchData);
  };

  renderButtons = (params) => {
    if (this.props.buttons) {
      return Object.keys(this.props.buttons).map((button, key) => {
        return (
          <Tooltip title={this.capitalizeFirstLetter(button)} key={button}>
            <span>{this.props.buttons[button](params, this.fetchData)}</span>
          </Tooltip>
        );
      });
    } else {
      return null;
    }
  };

  handleToolTipText = (additional_data, row) => {
    return additional_data.map((field, key) => {
      if (row.hasOwnProperty(field)) {
        if (row[field] !== null && row[field] !== undefined) {
          return (
            <div key={key}>
              {this.capitalizeFirstLetter(field)} :{" "}
              {row[field].length > 0 ? row[field] : "Not defined"}
            </div>
          );
        } else {
          return (
            <div key={key}>
              {this.capitalizeFirstLetter(field)} : {"Not defined"}
            </div>
          );
        }
      }
    });
  };

  handleColumns = () => {
    const columns = this.config.map((c, key) => {
      const config = {
        ...c,
        field: c.field,
        headerName: this.generateHeaderName(c),
        fontSize: "12px",
        flex: c.flex ? c.flex : 1,
        align: c.align ? c.align : "left",
        width: c.width ? c.width : "auto",
      };
      switch (c.datatype) {
        case data_types.boolean:
        case data_types.color:
        case data_types.number:
          config["align"] = "center";
          break;
        case data_types.date:
        case data_types.datetime:
        case data_types.time:
        case data_types.reference:
          config["align"] = "left";
          break;
      }

      config["align"] = c.align ? c.align : "left";

      if (
        c.datatype == data_types.reference &&
        this.references.hasOwnProperty(c.reference) == false
      ) {
        this.references[c.reference] = null;
        this.references.count++;
        this.references.arr.push(c.reference);
      }
      if (c.hasOwnProperty("renderCell") == false) {
        if (c.datatype == data_types.color) {
          config["renderCell"] = (params) => {
            if (params["value"] != null) {
              return (
                <Tooltip title={`${params["value"]}`} key={key}>
                  <div
                    key={key}
                    style={{
                      backgroundColor: `${params["value"]}`,
                      width: "15px",
                      height: "15px",
                      borderRadius: "30%",
                    }}
                  />
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title={`-`} key={key}>
                  <div key={key}>-</div>
                </Tooltip>
              );
            }
          };
        }

        if (c.datatype == data_types.time) {
          config["renderCell"] = (params) => {
            if (params["value"] != null) {
              return (
                <Tooltip
                  key={key}
                  title={`${moment(params["value"]).format(
                    "MM/DD/YYYY"
                  )}, ${moment(params["value"]).format("HH:mm:ss")}`}
                >
                  <div style={{ whiteSpace: "pre-line", textAlign: "center" }}>
                    {`${moment(params["value"]).format("MM/DD/YYYY")}\n${moment(
                      params["value"]
                    ).format("HH:mm:ss")}`}
                  </div>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip key={key} title={"-"}>
                  <div>-</div>
                </Tooltip>
              );
            }
          };
        }

        if (c.datatype == data_types.date) {
          config["renderCell"] = (params) => {
            if (params["value"] != null) {
              return (
                <Tooltip
                  key={key}
                  title={`${moment(params["value"]).format("MM/DD/YYYY")}`}
                >
                  <div style={{ whiteSpace: "pre-line" }}>
                    {`${moment(params["value"]).format("MM/DD/YYYY")}`}
                  </div>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip key={key} title={"-"}>
                  <div>-</div>
                </Tooltip>
              );
            }
          };
        }

        if (c.datatype == data_types.boolean) {
          config["renderCell"] = (params) => {
            if (params["value"] !== null) {
              return (
                <Tooltip key={key} title={params["value"] === 1 ? "Yes" : "No"}>
                  <div
                    style={{
                      color: `${params["value"] === 1 ? "#82D73F" : "#EC5F5F"}`,
                    }}
                  >
                    {params["value"] === 1 ? "Yes" : "No"}
                  </div>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip key={key} title={"-"}>
                  <div>{"-"}</div>
                </Tooltip>
              );
            }
          };
        }

        if (c.datatype == data_types.reference) {
          config["renderCell"] = (params) => {
            if (params["value"] !== null) {
              return (
                <Tooltip
                  key={key}
                  title={
                    params.value && this.references[c.reference][params.value]
                  }
                >
                  <div>
                    {params.value && this.references[c.reference][params.value]}
                  </div>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip key={key} title={"-"}>
                  <div>{"-"}</div>
                </Tooltip>
              );
            }
          };
        }
      } else if (c.hasOwnProperty("renderCell")) {
        const tempRenderCell = c.renderCell;
        c.renderCell = (params) => {
          if (params.value != null) {
            if (typeof params.value == "string") {
              if (params.value && params.value.length) {
                return c.datatype == data_types.reference
                  ? tempRenderCell(params, this.references[c.reference])
                  : tempRenderCell(params);
              } else {
                return (
                  <Tooltip title={"-"}>
                    <div>-</div>
                  </Tooltip>
                );
              }
            } else {
              return tempRenderCell(params);
            }
          } else {
            return (
              <Tooltip title={"-"}>
                <div>-</div>
              </Tooltip>
            );
          }
        };
      }
      return config;
    });
    if (global.UF.data_structure[this.props.table].additionalData) {
      const additionalData =
        global.UF.data_structure[this.props.table].additionalData;
      additionalData.forEach((objInConfig) => {
        let currentColumn = columns.find((col) => col.field == objInConfig);
        currentColumn.hide = true;
      });
      columns.push({
        headerName: "Additional Data",
        field: "additional_data",
        align: "center",
        flex: 1,
        sortable: false,
        width: "auto",
        renderCell: (params) => {
          if (params.row.notes !== null && params.row.notes.trim() !== "") {
            return (
              <Tooltip
                title={this.handleToolTipText(additionalData, params.row)}
              >
                <NotesIcon />
              </Tooltip>
            );
          } else {
            return <div />;
          }
        },
      });
    }
    columns.push({
      field: " ",
      headerName: "",
      align: "center",
      width: "max-content",
      sortable: false,
      flex: 1.6,
      renderCell: (params) => {
        return <div>{this.renderButtons(params)}</div>;
      },
    });
    return columns;
  };

  handleCellClick = (params) => {
    const ds = global.UF.data_structure[this.props.table];
    const fieldName = params.field;
    const currentField = ds.fieldsArr.find((field) => field.Field == fieldName);
    if (currentField && currentField.hasOwnProperty("onClick")) {
      currentField.onClick(params.row);
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (
      this.props.router.location.pathname != nextProps.router.location.pathname
    ) {
      this.fetchData(nextProps.filters);
    }
    if (nextState.activeRows.length) {
      if (nextState.activeRows.length == 1) {
        nextProps.popOverButtons.edit = () => {
          return (
            <Grid style={{ justifyContent: "center", alignItems: "center" }}>
              <IconButton
                onClick={() => nextProps.onEdit(nextState.activeRows[0])}
                size={"large"}
              >
                <EditIcon fontSize={"large"} sx={{ color: "#2D5DFCB2" }} />
              </IconButton>
            </Grid>
          );
        };
      } else {
        if (nextProps.popOverButtons.edit) {
          delete nextProps.popOverButtons.edit;
        }
      }
    }

    if (this.props.id != nextProps.id) {
      this.fetchReferences(true);
    }
    return true;
  };

  fetchData = (filters = this.props.filters) => {
    if (this.state.isLoading == false) {
      this.setState({ isLoading: true });
    }

    const qs = Object.assign(
      {
        page: this.state.page,
        size: this.state.pageSize,
      },
      filters ? filters : {}
    );

    if (this.props.customFiltering) {
      this.props.customFiltering(qs);
    }

    const ds = global.UF.dataProvider.datastructure[this.props.table];

    if (ds.fields.hasOwnProperty("event_id")) {
      qs["event_id"] = global.UF.event_id;
    }

    if (ds.fields.hasOwnProperty("award_id")) {
      qs["award_id"] = global.UF.award_id;
    }

    if (ds.hasOwnProperty("filters") && ds.filters) {
      Object.keys(ds.filters).forEach((filter) => {
        qs[filter] = ds.filters[filter];
      });
    }

    if (this.state.sortField.length > 0) {
      qs.order_by = this.state.sortField[0].field;
      qs.order = this.state.sortField[0].sort;
    }

    if (this.props.syncData) {
      this.props.syncData(this.fetchData, qs);
    }

    global.UF.dataProvider.get(this.props.table, qs, (data, totalItems) => {
      if (this.props.onDataItemsLoad) {
        this.props.onDataItemsLoad(data, () => {
          this.setState({ data, totalItems, isLoading: false });
        });
      } else {
        this.setState({ data, totalItems, isLoading: false });
      }
    });
  };

  handleSelectionModelChange = (rowsIds) => {
    const rows = rowsIds.map((id) =>
      this.state.data.find((row) => row.id === id)
    );
    if (rows.length) {
      this.setState({ popoverActive: true, activeRows: rows });
    } else {
      this.setState({ popoverActive: false, activeRows: rows });
    }
  };

  componentWillReceiveProps = (props) => {
    if (props.lastUpdate != this.lastUpdate) {
      this.lastUpdate = props.lastUpdate;
      this.fetchData(props.filters);
    }
  };

  renderPopOver = () => {
    return (
      <Nx_Grid_Popover
        show={this.state.popoverActive}
        buttons={this.props.popOverButtons}
        rows={this.state.activeRows}
        fetchData={this.fetchData}
      />
    );
  };

  render() {
    return (
      <Fragment>
        {this.state.popoverActive && this.renderPopOver()}
        <DataGrid
          rows={this.state.data}
          rowHeight={
            this.props.rowHeight ? this.props.rowHeight : this.state.rowHeight
          }
          columns={this.handleColumns()}
          rowCount={this.state.totalItems}
          page={this.state.page}
          pageSize={this.state.pageSize}
          paginationMode="server"
          onPageChange={(newPage) => {
            this.state.page = newPage;
            this.fetchData();
          }}
          onPageSizeChange={(newPageSize) => {
            this.state.pageSize = newPageSize;
            this.fetchData();
          }}
          rowReordering
          initialState={{
            sorting: {
              sortModel: global.UF.data_structure[
                this.props.table
              ].hasOwnProperty("sorting")
                ? global.UF.data_structure[this.props.table]["sorting"]
                : this.state.sortField,
            },
          }}
          onCellClick={this.handleCellClick}
          checkboxSelection={true}
          disableSelectionOnClick
          onSelectionModelChange={(items) =>
            this.handleSelectionModelChange(items)
          }
          onRowDoubleClick={
            this.props.rowDoubleClick
              ? (params) => this.props.rowDoubleClick(params)
              : () => {}
          }
          rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
          disableColumnMenu
          sortingMode="server"
          onSortModelChange={this.handleSortModelChange}
          className="nx_grid_wrapper"
        />
      </Fragment>
    );
  }
}

export default withRouter(Nx_Grid);

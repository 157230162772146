import React, {useState} from 'react';
import Grid from "../ui_components/layouts/Grid/Grid"
import CircularProgress from '@mui/material/CircularProgress';
import "./nx_grid.scss"

const Nx_Loader = (props) => {
    const [show, setShow] = useState(props ? props.show : false)
    global.loadingSetVisibility = setShow

    return (
        <Grid fullScreen style={{visibility : show ? 'unset' : 'hidden'}} className={"loader"}>
            <CircularProgress color={"primary"}/>
        </Grid>
    )
}

export default Nx_Loader
import React, {Fragment} from 'react'
import './print.css'
import UF_Button from "../../../nix_components/ui_components/controls/nx_button.js"
import Nx_Ctrl_TextInput from "../../../nix_components/ui_components/controls/nx_ctrl_textinput"


class Print extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            marginTop: null,
            marginBetween: null
        }
        this.firstText = React.createRef()
        this.secondText = React.createRef()
    }

    onChange = (e, margin) => {
        if (margin === 'marginTop') {
            this.firstText.current.style.marginTop = `${e.target.value}px`
        }
        else {
            this.secondText.current.style.marginBottom = `${e.target.value}px`
        }
        this.state[margin] = e.target.value
        this.forceUpdate()
    }

    print = () => {
        global.printElement(document.getElementsByClassName('print')[0])
        window.print()
    }


    render() {
        return (
            <Fragment>
                <div className="print-wrapper">
                    <div className="print">
                        <div className="print-first-text" ref={this.firstText}>
                            <div style={{fontSize : 28, fontWeight : 'bold'}}>Guest Guesterson</div>
                            <div style={{fontSize : 24}}>Test Company</div>
                            <div style={{fontSize : 24}}>Promo Momo</div>
                        </div>
                        <div className="print-second-text" ref={this.secondText}>
                            <div style={{fontSize : 28, fontWeight : 'bold'}}>Guest Guesterson</div>
                            <div style={{fontSize : 24}}>Test Company</div>
                            <div style={{fontSize : 24}}>Promo Momo</div>
                        </div>
                    </div>
                </div>
                <span style={{position: 'absolute', top: '50px'}} className={"print-text"}>
                    <Nx_Ctrl_TextInput type="number" label={'Margin top'} value={this.state.marginTop}
                                  onChange={(e) => this.onChange(e, 'marginTop')}/>
                    <span style={{position: 'absolute', top: '12px', left: '156px'}}>px</span>
                </span>
                <span style={{position: 'absolute', top: '50px', left: '200px'}} className={"print-text"}>
                    <Nx_Ctrl_TextInput type="number" label={'Margin between'} value={this.state.marginBetween}
                                  onChange={(e) => this.onChange(e, 'marginBetween')}/>
                    <span style={{position: 'absolute', top: '13px', left: '156px'}}>px</span>
                </span>
                <div style={{position: 'absolute', top: '125px'}} className="print-button">
                    <UF_Button onClick={this.print} label={'Print'}/>
                </div>
            </Fragment>
        )
    }
}

export default Print
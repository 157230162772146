import React, { Fragment } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import data_types from "../../../../nix_components/data/data_types";
import { validators } from "../../../../nix_components/data/data_validators";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import TransferDataModal from "../../../../components/modals/TransferDataModal";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { IconButton } from "@mui/material";
class RegistrationPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: "package",
      transferOpen: false,
      scopeCondition: false,
      loaded: false,
    };
  }

  tools = {
    transfer: () => {
      return this.state.scopeCondition ? (
        <Tooltip title={"Transfer"}>
          <IconButton onClick={() => this.setState({ transferOpen: true })}>
            <MoveUpIcon />
          </IconButton>
        </Tooltip>
      ) : null;
    },
  };

  buttons = {};

  formConfig = {
    rows: [
      [
        {
          field: "published",
          label: "Published",
          datatype: data_types.boolean,
        },
      ],
      ["name", "color", "auto_approval"],
      [
        {
          label: "Subtitle",
          field: "subtitle",
          multiline: true,
          helperText: "Text needs to be dellimited by /",
          datatype: data_types.string,
        },
      ],
      [
        {
          label: "Description",
          field: "description",
          multiline: true,
          defaultValue: "",
          datatype: data_types.string,
        },
      ],
      [
        {
          field: "available_until",
          datatype: data_types.date,
          label: "Available Until",
          validators: [validators.isDateBeforeToday],
        },
        {},
        {},
      ],
      [
        {
          label: "Early-bird pricing",
          datatype: data_types.boolean,
          field: "earlyBirdPricing_enabled",
          defaultValue: false,
        },
      ],
      [
        {
          label: "Price",
          datatype: data_types.number,
          field: "earlyBirdPricing_price",
          validators: [validators.isNegative],
        },
        {
          label: "Expiration date",
          datatype: data_types.date,
          field: "earlyBirdPricing_date",
          defaultValue: new Date(),
          validators: [validators.isDateBeforeToday],
        },
        {
          label: "External ID",
          datatype: data_types.string,
          field: "earlyBirdPricing_id",
        },
        {
          label: "NO VAT External ID",
          datatype: data_types.string,
          field: "earlyBirdPricing_no_vat_id",
        },
      ],
      [
        {
          label: "Standard pricing",
          datatype: data_types.boolean,
          field: "standardPricing_enabled",
          defaultValue: false,
        },
      ],
      [
        {
          label: "Price",
          datatype: data_types.number,
          field: "standardPricing_price",
          validators: validators.isNegative,
        },
        {
          label: "Expiration date",
          datatype: data_types.date,
          field: "standardPricing_date",
          defaultValue: new Date(),
          validators: validators.isDateBeforeToday,
        },
        {
          label: "External ID",
          datatype: data_types.string,
          field: "standardPricing_id",
        },
        {
          label: "NO VAT External ID",
          datatype: data_types.string,
          field: "standardPricing_no_vat_id",
        },
      ],
      [
        {
          label: "On-spot pricing",
          datatype: data_types.boolean,
          field: "onSpotPricing_enabled",
        },
      ],
      [
        {
          label: "Price",
          datatype: data_types.number,
          field: "onSpotPricing_price",
          validators: [validators.isNegative],
        },
        {
          label: "Expiration date",
          datatype: data_types.date,
          defaultValue: new Date(),
          field: "onSpotPricing_date",
          validators: [validators.isDateBeforeToday],
        },
        {
          label: "External ID",
          datatype: data_types.string,
          field: "onSpotPricing_id",
          validators: [validators.isNegative],
        },
        {
          label: "NO VAT External ID",
          datatype: data_types.string,
          field: "onSpotPricing_no_vat_id",
        },
      ],
    ],
  };

  gridConfig = {
    columns: [
      {
        field: "event_id",
        label: "Event",
      },
      "color",
    ],
  };

  gridProps = {
    fields: [
      "name",
      "prices",
      "available_until",
      "ordering",
      "subtitle",
      "auto_approval",
      "published",
    ],
  };

  onBeforeDataItemSave = (dataItem) => {
    dataItem.prices = {
      on_spot: {
        value: dataItem["onSpotPricing_price"],
        expires_at: dataItem["onSpotPricing_date"],
        external_id: dataItem["onSpotPricing_id"],
        no_vat_external_id: dataItem["onSpotPricing_no_vat_id"],
      },
      regular: {
        value: dataItem["standardPricing_price"],
        expires_at: dataItem["standardPricing_date"],
        external_id: dataItem["standardPricing_id"],
        no_vat_external_id: dataItem["standardPricing_no_vat_id"],
      },
      early_bird: {
        value: dataItem["earlyBirdPricing_price"],
        expires_at: dataItem["earlyBirdPricing_date"],
        external_id: dataItem["earlyBirdPricing_id"],
        no_vat_external_id: dataItem["earlyBirdPricing_no_vat_id"],
      },
    };
  };

  onDataItemsLoad = (items, callback) => {
    items.forEach((item) => {
      const prices = JSON.parse(item.prices);

      item["earlyBirdPricing_enabled"] = true;
      item["earlyBirdPricing_price"] =
        prices && prices.early_bird && prices.early_bird.hasOwnProperty("value")
          ? prices.early_bird.value
          : 0;
      item["earlyBirdPricing_date"] =
        prices &&
        prices.early_bird &&
        prices.early_bird.hasOwnProperty("expires_at")
          ? prices.early_bird.expires_at
          : new Date();
      item["earlyBirdPricing_id"] =
        prices &&
        prices.early_bird &&
        prices.early_bird.hasOwnProperty("external_id")
          ? prices.early_bird.external_id
          : "";
      item["earlyBirdPricing_no_vat_id"] =
        prices &&
        prices.early_bird &&
        prices.early_bird.hasOwnProperty("no_vat_external_id")
          ? prices.early_bird.no_vat_external_id
          : "";

      item["standardPricing_enabled"] = true;
      item["standardPricing_price"] =
        prices && prices.regular && prices.regular.hasOwnProperty("value")
          ? prices.regular.value
          : null;
      item["standardPricing_date"] =
        prices && prices.regular && prices.regular.hasOwnProperty("expires_at")
          ? prices.regular.expires_at
          : new Date();
      item["standardPricing_id"] =
        prices && prices.regular && prices.regular.hasOwnProperty("external_id")
          ? prices.regular.external_id
          : null;
      item["standardPricing_no_vat_id"] =
        prices &&
        prices.regular &&
        prices.regular.hasOwnProperty("no_vat_external_id")
          ? prices.regular.no_vat_external_id
          : null;

      item["onSpotPricing_enabled"] = true;
      item["onSpotPricing_price"] =
        prices && prices.on_spot && prices.on_spot.hasOwnProperty("value")
          ? prices.on_spot.value
          : null;
      item["onSpotPricing_date"] =
        prices && prices.on_spot && prices.on_spot.hasOwnProperty("expires_at")
          ? prices.on_spot.expires_at
          : new Date();
      item["onSpotPricing_id"] =
        prices && prices.on_spot && prices.on_spot.hasOwnProperty("external_id")
          ? prices.on_spot.external_id
          : null;
      item["onSpotPricing_no_vat_id"] =
        prices &&
        prices.on_spot &&
        prices.on_spot.hasOwnProperty("no_vat_external_id")
          ? prices.on_spot.no_vat_external_id
          : null;
    });
    callback();
  };

  handleBeforeAddNew = (dataItem, callback) => {
    dataItem.event_id = global.UF.event_id;
    global.UF.makeRequest(
      `POST`,
      `/api/get-ordering`,
      { table: "packages" },
      true,
      (data) => {
        dataItem.ordering = data.body.ordering;
        if (callback) {
          callback();
        }
      },
      (error) => {
        console.error(error);
      },
      true,
      false
    );
  };

  renderTransferDataModal = () => {
    return (
      <TransferDataModal
        open={this.state.transferOpen}
        onClose={() => this.setState({ transferOpen: false })}
        table={"packages"}
      />
    );
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  componentDidMount() {
    this.findCurrentPermission();
  }

  render() {
    return (
      <Fragment>
        {this.state.transferOpen && this.renderTransferDataModal()}
        <UF_Content
          table="packages"
          id={this.props.event_id}
          title="Packages"
          tools={this.tools}
          buttons={this.buttons}
          edit={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          export={this.state.scopeCondition}
          onBeforeDataItemSave={this.onBeforeDataItemSave}
          onDataItemsLoad={this.onDataItemsLoad}
          formConfig={this.formConfig}
          gridProps={this.gridProps}
          permission={"package"}
          add={this.state.scopeCondition}
          sync={this.state.scopeCondition}
          beforeAddNew={this.handleBeforeAddNew}
        />
      </Fragment>
    );
  }
}

export default RegistrationPackages;
